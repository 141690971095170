import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Lightroom from "react-lightbox-gallery";


function Data_processing() {

    var images = [
        {
            src: "img/data-tabulation/1.jpg",
            src_col:"img/data-tabulation/icons/1-300px.png",
            desc: "Cluster Analysis",

        },
        {
            src: "img/data-tabulation/2.jpg",
            desc: "Factor Analysis",
            src_col:"img/data-tabulation/icons/2-300px.png",

        },
      
        {
            src: "img/data-tabulation/3.jpg",
            desc: "Key Driver Analysis",
            src_col:"img/data-tabulation/icons/3-300px.png",

        },
        {
            src: "img/data-tabulation/4.jpg",
            desc: "Brand Health Analysis",
            src_col:"img/data-tabulation/icons/4-300px.png",

        },
        {
            src: "img/data-tabulation/5.jpg",
            desc: "Price Sensitivity Analysis",
            src_col:"img/data-tabulation/icons/5-300px.png",

        },

        {
            src: "img/data-tabulation/6.jpg",
            src_col:"img/data-tabulation/icons/6-300px.png",
            desc: "MaxDiff Simulator Analysis",

        },
        {
            src: "img/data-tabulation/7.jpg",
            desc: "Correspondence Analysis Map",
            src_col:"img/data-tabulation/icons/7-300px.png",

        },
       
        {
            src: "img/data-tabulation/8.jpg",
            desc: "Brand Performance Score Card",
            src_col:"img/data-tabulation/icons/8-300px.png",

        },
        {
            src: "img/data-tabulation/9.jpg",
            desc: "Data Tabulation with Sig Test",
            src_col:"img/data-tabulation/icons/9-300px.png",

        },
        {
            src: "img/data-tabulation/10.jpg",
            desc: "Data Tabulation",
            src_col:"img/data-tabulation/icons/10-300px.png",

        },
        {
            src: "img/data-tabulation/11.jpg",
            desc: "Market Segmentation",
            src_col:"img/data-tabulation/icons/11-300px.png",

        },
        {
            src: "img/data-tabulation/12.jpg",
            desc: "Conjoint Analysis",
            src_col:"img/data-tabulation/icons/12-300px.png",

        },
        {
            src: "img/data-tabulation/13.jpg",
            desc: "TURF Analysis",
            src_col:"img/data-tabulation/icons/13-300px.png",

        },
        {
            src: "img/data-tabulation/14.jpg",
            desc: "GAP Analysis",
            src_col:"img/data-tabulation/icons/14-300px.png",

        },
        {
            src: "img/data-tabulation/15.jpg",
            desc: "SWAT Analysis",
            src_col:"img/data-tabulation/icons/15-300px.png",

        },
        {
            src: "img/data-tabulation/16.jpg",
            desc: "Text Analysis",
            src_col:"img/data-tabulation/icons/16-300px.png",

        },
        {
            src: "img/data-tabulation/17.jpg",
            desc: "Trend Analysis",
            src_col:"img/data-tabulation/icons/17-300px.png",

        },
        {
            src: "img/data-tabulation/18.jpg",
            desc: "Dispersion Analysis",
            src_col:"img/data-tabulation/icons/18-300px.png",

        },
        {
            src: "img/data-tabulation/19.jpg",
            desc: "Time Series Analysis",
            src_col:"img/data-tabulation/icons/19-300px.png",

        },
        {
            src: "img/data-tabulation/20.jpg",
            desc: "Discriminant Analysis",
            src_col:"img/data-tabulation/icons/20-300px.png",

        },
        
       
     
      
      
       
      

    ];
    var settings = {
        columnCount: {
            default: 4,
            mobile: 4,
            // desktop:4,
            tab: 4
        },
        mode: "dark"
    };


    useEffect(() => {

        document.title = "Data Modeling - Mindforce"



    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini Tabulation_sec">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app ">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a > <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Data-Modeling.png'></img>Data Modeling</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                {/* <div className='gif_comingsoon'>

                       <img src='/img/coming-soon.png'/>
                       
                       </div> */}

                                {/* <div className="light_room">
                                    <Lightroom images={images} settings={settings} />
                                    
                                    
                                </div> */}



                                <div className='segment_sec Tabulation'>
                                    {images.map((item, index) => {
                                        return <div className='' >
                                            <div class="SearchResults-module_result_3-uXL worksheet"><div class=""><a href={item.src} data-lightbox="photos" ><div class="ContentCard-module_card_1xFCo ContentCard-module_grid_2cGfg"><a class="ContentCard-module_link_bi8gq" href="" data-id="63741" data-type="worksheet"><div class="ContentCard-module_front__1Cvl"><div class="ContentCard-module_image_1D6-7 ContentCard-module_narrow_1Ktvs"><img alt="1-Minute Multiplication" src={item.src_col} /></div><div class="ContentCard-module_titleArea_sGOV3">
                                                <div class="font-primary-bold Title-module_title_21JAg Title-module_grid_3aQxO"><span>{item.desc}</span></div>
                                                <div class="ContentType-module_contentTypeHolder_7Ku6w ContentType-module_grid_1TWyv"> <div class="ContentType-module_contentTypeName_2IUAM">
                                                </div></div></div></div></a>
                                            </div></a></div></div>
                                        </div>
                                    })}
                                </div>



                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Data_processing