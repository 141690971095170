import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function How_businesses_can_leverage_big_data() {


    useEffect(() => {

        document.title = "How businesses can leverage Big Data? - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src="/img/icons/Casestudy.png" />Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-10.jpg" alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>How businesses can leverage Big Data?</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-detail pt-100">
                                                    <div class="cases-content">
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Objective</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>Our client wanted to identify the big data and predictive analytics initiatives and strategies involved by medium and large companies. The customer trends and preferences to take better business decisions.</p>                                        </div>
                                                        </div>
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Methodology</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>CATI</p>                                        </div>
                                                        </div>
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Respondents</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>IT Managers and above</p>                                        </div>
                                                        </div>
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Sample</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>400 interviews </p>                                        </div>
                                                        </div>
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Geography</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>North America and Europe</p>                                        </div>
                                                        </div>
                                                        <div class="cases-box">
                                                            <div class="cases-left">
                                                                <span class="cases-title">Result</span>
                                                            </div>
                                                            <div class="cases-right">
                                                                <p>The outcome of the study helped the client understand where organizations are on the big data adoption curve across multiple business functions.  The study provided insights related to useful predictive analytics down to the departmental level.</p>                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default How_businesses_can_leverage_big_data