import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Reset_password_page() {

    

    return (
        <div className="page-main">

            {/* <!-- App-Content -->			 */}
            <div className="main-content">
                <div className="side-app">

                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh align-items-center background-white">
                            <div className="col-lg-5 col-md-12 col-sm-12 h-100" id="login-responsive">
                                <div className="card-body pr-9 pl-9 pt-7">
                                    <img src='/img/logo-big.png' className='mb-4'></img>
                                    <div className="card border-0">
                                            <div className="card-body p-5 pb-0">
                                                <div className="divider">
                                                    <div className="divider-text text-muted">
                                                        <small> Reset Password </small>
                                                    </div>
                                                </div>


                                                <div className="input-box mb-4">
                                                    <label for="passwordPass" className="fs-12 font-weight-bold text-md-right">New Password<span className='errorMessage'> {}</span></label>
                                                    <input id="passwordPass" type="password" className="form-control " name="passwordPass" autocomplete="off" placeholder="Enter your new password"  required />

                                                </div>
                                                <div className="input-box mb-4">
                                                    <label for="passwordConf" className="fs-12 font-weight-bold text-md-right">Confirm Password<span className='errorMessage'> {}</span></label>
                                                    <input id="passwordConf" type="password" className="form-control " name="passwordConf" autocomplete="off" placeholder="Enter to confirm password"  required />
                                                </div>

                                            

                                                <div className="form-group mb-0">
                                                    <span className='errorMessage mt-0'> </span>
                                                    <label for="error" className="form-label"> </label>
                                                    <button  className="btn btn-primary mr-2">Reset Password</button>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="">
                                                        <label className="custom-switch">
                                                            <span className="custom-switch-description">Don't have an account</span> <a className="text-info fs-12" href="/sign-up">Sign Up</a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                            </div>

                            <div className="col-lg-7 col-md-12 col-sm-12 text-center background-special h-100 align-middle pl-0 pr-0" id="login-background">
                                <div className="login-bg"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    )
}

export default Reset_password_page