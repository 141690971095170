import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Segmentcard from '../../Segmentcard';

function Sample_distribution() {
    const [selectedRadiosB2b, setSelectedRadiosB2b] = useState([]);
    const [selectedRadiosB2c, setSelectedRadiosB2c] = useState([]);
    const [isDisableB2b, setIsDisableB2b] = useState(false);
    const [isDisableB2c, setIsDisableB2c] = useState(false);

    const handleRadioChangeB2b = (radioValueB2B) => {
        if(isDisableB2b === false){
            let tempSelectRadioB2b = [...selectedRadiosB2b];
            if (tempSelectRadioB2b.includes(radioValueB2B)) {
                    tempSelectRadioB2b = tempSelectRadioB2b.filter((value) => value !== radioValueB2B);
              } else if (tempSelectRadioB2b.length < 3) {
                tempSelectRadioB2b = [...selectedRadiosB2b, radioValueB2B];
              }
              if(tempSelectRadioB2b.length > 0){
                setIsDisableB2c(true);
              }
              if(tempSelectRadioB2b.length == 0){
                setIsDisableB2c(false);
              }
              setSelectedRadiosB2b(tempSelectRadioB2b);
        }
        
         
    };
   //console.log(isDisableB2c);
    const handleRadioChangeB2c = (radioValueB2c) => {
        if(isDisableB2c === false){
            let tempSelectRadioB2c = [...selectedRadiosB2c];
            if (tempSelectRadioB2c.includes(radioValueB2c)) {
                    tempSelectRadioB2c = tempSelectRadioB2c.filter((value) => value !== radioValueB2c);
              } else if (tempSelectRadioB2c.length < 3) {
                tempSelectRadioB2c = [...selectedRadiosB2c, radioValueB2c];
              }
              if(tempSelectRadioB2c.length > 0){
                setIsDisableB2b(true);
              }
              if(tempSelectRadioB2c.length == 0){
                setIsDisableB2b(false);
              }
              setSelectedRadiosB2c(tempSelectRadioB2c);
            }
      };
   
  
      



    useEffect(() => {

        document.title = "User Segmentation - Mindforce"



    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a > <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Sample-Distribution.png'></img>Sample Distribution</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <section className='sample-distribution-sec'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div className='sample-distribution-sidebar bg-blue  p-4'>
                                                <h3 className='h5'> Survey Sampling & Distribution</h3>
                                                <div className={`b2b-b2c-box ${isDisableB2b ? 'disabled': ''}` }>
                                                    <h3 className=''>B2B</h3>
                                                    <h4 className='h6'>Select any three</h4>
                                                    <div className='checkboxes-box'>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                            name="slectB2B1" id="slectB2B1"
                                                                value={1}
                                                                checked={selectedRadiosB2b.includes('1')}
                                                                onChange={() => handleRadioChangeB2b('1')}
                                                             />
                                                            <label class="form-check-label" for="slectB2B1">
                                                                By Industry
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2B2" id="slectB2B2"
                                                            value={2}
                                                            checked={selectedRadiosB2b.includes('2')}
                                                            onChange={() => handleRadioChangeB2b('2')} />
                                                            <label class="form-check-label" for="slectB2B2">
                                                                By Employee Size
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2B3" id="slectB2B3" 
                                                            value={3}
                                                            checked={selectedRadiosB2b.includes('3')}
                                                            onChange={() => handleRadioChangeB2b('3')}/>
                                                            <label class="form-check-label" for="slectB2B3">
                                                                By Revenue
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2B4" id="slectB2B4" 
                                                            value={4}
                                                            checked={selectedRadiosB2b.includes('4')}
                                                            onChange={() => handleRadioChangeB2b('4')} />
                                                            <label class="form-check-label" for="slectB2B4">
                                                                By Job Title                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2B5" id="slectB2B5" 
                                                            value={5}
                                                            checked={selectedRadiosB2b.includes('5')}
                                                            onChange={() => handleRadioChangeB2b('5')}
                                                             />
                                                            <label class="form-check-label" for="slectB2B5">
                                                                By Department
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  className={`b2b-b2c-box mt-4 ${isDisableB2c ? 'disabled': ''}`}>
                                                    <h3 className=''>B2C</h3>
                                                    <h4 className='h6'>Select any three</h4>
                                                    <div className='checkboxes-box'>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2C1" id="slectB2C1" 
                                                                value={1}
                                                                checked={selectedRadiosB2c.includes('1')}
                                                                onChange={() => handleRadioChangeB2c('1')}/>
                                                            <label class="form-check-label" for="slectB2C1">
                                                                By Gender
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2C2" id="slectB2C2" 
                                                                value={2}
                                                                checked={selectedRadiosB2c.includes('2')}
                                                                onChange={() => handleRadioChangeB2c('2')}/>
                                                            <label class="form-check-label" for="slectB2C2">
                                                                By Age
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2C3" id="slectB2C3" 
                                                                value={3}
                                                                checked={selectedRadiosB2c.includes('3')}
                                                                onChange={() => handleRadioChangeB2c('3')}/>
                                                            <label class="form-check-label" for="slectB2C3">
                                                                By Occupation
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="slectB2C4" id="slectB2C4" 
                                                                value={4}
                                                                checked={selectedRadiosB2c.includes('4')}
                                                                onChange={() => handleRadioChangeB2c('4')} />
                                                            <label class="form-check-label" for="slectB2C4">
                                                                By MHI
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='selected-items bg-light p-3'>
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('1') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Industry & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-success table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-success'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Industry</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Business & Consumer services</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>BFSI</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('2') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Employee & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-warning table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-warning'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Employee Size</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>1 to 259</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>250 to 499</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('3') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Revenue & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-danger table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-danger'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Revenue</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('4') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Title & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-primary table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-secondary'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Job Title</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('5') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Department & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-primary table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-primary'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Department</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('1') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select By Gender </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-warning table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-warning'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Gender</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Male</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>Female</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('2') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select By Age Group </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-info table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-info'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Age Group</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>15-17 Years</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>18-24 Years</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('3') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select By Occupation </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-success table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-success'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Occupation</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Student</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>Home Maker</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('4') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select By Monthly Household Income</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-danger table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-danger'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">MHI</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td> &lt; $200</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>$200-$500</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='table-box-main'>
                                                
                                                <div className='table-responsive'>
                                                    <table class="table table-bordered table-sm">
                                                        <thead className='text-center'>
                                                            <tr className='bg-blue'>
                                                                <td colSpan='3'>
                                                                    <h3 className='h4 m-0'>Total Sample: 500</h3>
                                                                </td>
                                                                 <td colSpan='6'>
                                                                    <h3 className='h4 m-0'>Employee Size</h3>
                                                                 </td>
                                                            </tr>
                                                            <tr className='bg-light'>
                                                                <th scope="col">Employee Size</th>
                                                                <th scope="col">Title</th>
                                                                <th scope="col">Total</th>
                                                                <th scope="col">Business & Services</th>
                                                                <th scope="col">BFSI</th>
                                                                <th scope="col">Electronics</th>
                                                                <th scope="col">Manufactring</th>
                                                                <th scope="col">Telecom & media</th>
                                                                <th scope="col">Energy & Utilities</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='bg-success'>
                                                                <th scope="row">1 to 259</th>
                                                                <td>Sample Distribution</td>
                                                                <td>50</td>
                                                                <td>10</td>
                                                                <td>13</td>
                                                                <td>5</td>
                                                                <td>5</td>
                                                                <td>10</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row"></th>
                                                                <td>c-Level</td>
                                                                <td>3</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row"></th>
                                                                <td>SVP/VP/EVP</td>
                                                                <td>3</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>1</td>
                                                                <td>8</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Sample_distribution