import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

function Sidebar() {
    const spaax = window.location.pathname;
    return (
        <div className="app sidebar-mini">
            <div className="page">
                <div className="page-main">
                    <aside className="app-sidebar ">
                        <div className="app-sidebar__logo">
                            <a className="header-brand" href="/">
                                <img src="/img/logo-big.png" className="header-brand-img desktop-lgo" alt="Admintro logo" />
                                <img src="/img/favi.png" className="header-brand-img mobile-logo" alt="Admintro logo" />
                            </a>
                        </div>
                        <div className='scrool'>
                            <ul className="side-menu app-sidebar3">

                                <li className={(spaax === '/dashboard') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/dashboard') ? 'side-menu__item active' : 'side-menu__item'} to="/dashboard">
                                        {/* <span className="side-menu__icon fa fa-th-large"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Dashboard.png'></img>
                                         <span className="side-menu__label">Dashboard</span>
                                       
                                    </NavLink>
                                </li>
                                {/* <hr className ="slide-divider" /> */}
                                {/* <li className ="side-item side-item-category">Programs</li> */}
                                <li className={(spaax === '/business-to-business' || spaax === '/business-to-consumers' || spaax === '/retail') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <a className="side-menu__item" data-toggle="slide" href="">
                                        {/* <span className="side-menu__icon enlarge lead-3 fa fa-magic"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Panelbook.png'></img>
                                        <span className="side-menu__label">Panel Book</span><i className="angle fa fa-angle-right"></i>
                                    </a>
                                    <ul className="slide-menu">
                                        <li><NavLink to="/business-to-business" className={spaax === '/business-to-business' ? ('slide-item active') : 'slide-item'} >B2B</NavLink></li>
                                        <li><NavLink to="/business-to-consumers" className={spaax === '/business-to-consumers' ? ('slide-item active') : 'slide-item'}>B2C</NavLink></li>
                                     
                                    </ul>
                                </li>
                                <li className={(spaax === '/survey-scripting' || spaax === '/survey-scripting' || spaax === '/survey-scripting') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink to="/survey-scripting" className={(spaax === '/survey-scripting' || spaax === '/survey-scripting' || spaax === '/survey-scripting') ? 'side-menu__item active' : 'side-menu__item'}>
                                        {/* <span className="side-menu__icon mdi mdi-account-convert"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Survey-Scripting.png'></img>
                                        <span className="side-menu__label">Survey Scripting</span></NavLink>

                                </li>
                                <li className={(spaax === '/sample-distribution' || spaax === '/sample-distribution' || spaax === '/sample-distribution') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink to="/sample-distribution" className={(spaax === '/sample-distribution' || spaax === '/sample-distribution' || spaax === '/sample-distribution') ? 'side-menu__item active' : 'side-menu__item'}>
                                        {/* <span className="side-menu__icon mdi mdi-account-convert"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Sample-Distribution.png'></img>
                                        <span className="side-menu__label">Sample Distribution</span></NavLink>

                                </li>
                                {/* <li className={(spaax === '/survey-sampling/b2b' || spaax === '/survey-sampling/b2c' || spaax === '/survey-sampling/retail') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <a className="side-menu__item" data-toggle="slide" href="#">
                                        <img className="side-menu__icon" src='/img/SurveySampling.png'></img>
                                        <span className="side-menu__label">Survey Sampling</span>
                                        <i className="angle fa fa-angle-right"></i>
                                    </a>
                                    <ul className="slide-menu">
                                        <li><NavLink to="/survey-sampling/b2b" className={spaax === '/survey-sampling/b2b' ? ('slide-item active') : 'slide-item'}>B2B</NavLink></li>
                                        <li><NavLink to="/survey-sampling/b2c" className={spaax === '/survey-sampling/b2c' ? ('slide-item active') : 'slide-item'}>B2C</NavLink></li>
                    
                                    </ul>
                                </li> */}
                                <li className={(spaax === '/data-modeling') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/data-modeling') ? 'side-menu__item active' : 'side-menu__item'} to="/data-modeling">
                                        {/* <span className="side-menu__icon mdi mdi-account-alert"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Data-Modeling.png'></img>
                                        <span className="side-menu__label">Data Modeling</span>

                                    </NavLink>
                                </li>

                                {/* <li className={(spaax==='/data-analysis' ) ? 'slide is-expanded' : 'slide is-expanded'}>
                                <NavLink className={(spaax==='/data-analysis' )  ? 'side-menu__item active': 'side-menu__item'} to="/data-analysis">
                                       <img className="side-menu__icon" src='/img/DataAnalysis.png'></img>
                                        <span className="side-menu__label">Data Analysis</span>

                                    </NavLink>

                                </li> */}
                                {/* <li className={(spaax === '/statistical-modelling') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/statistical-modelling') ? 'side-menu__item active' : 'side-menu__item'} to="/statistical-modelling">
                                      
                                        <img className="side-menu__icon" src='/img/StatisticalModelling.png'></img>
                                        <span className="side-menu__label">Statistical Modelling</span></NavLink>

                                </li> */}



                                {/* <li className={(spaax === '/charting') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/charting') ? 'side-menu__item active' : 'side-menu__item'} to="/charting">

                                        <img className="side-menu__icon" src='/img/Chart.png'></img>
                                        <span className="side-menu__label">Charting</span></NavLink>
                                </li> */}

                                {/* <li className={(spaax === '/Brand Track') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/Brand Track') ? 'side-menu__item active' : 'side-menu__item'} to="/Brand Track">
                                        
                                        <img className="side-menu__icon" src='/img/Score.png'></img>
                                        <span className="side-menu__label">Brand Track</span></NavLink>
                                </li> */}
                                {/* <li className={(spaax === '/size-estimation') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/size-estimation') ? 'side-menu__item active' : 'side-menu__item'} to="/size-estimation">
                                      
                                        <img className="side-menu__icon" src='/img/SizeEstimation.png'></img>
                                        <span className="side-menu__label">Size Estimation</span></NavLink>
                                </li> */}
                                {/* <li className={(spaax === '/usage-habits') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/usage-habits') ? 'side-menu__item active' : 'side-menu__item'} to="/usage-habits">
                                        
                                        <img className="side-menu__icon" src='/img/UsageHabitat.png'></img>
                                        <span className="side-menu__label">Usage Habits</span></NavLink>

                                </li> */}
                                <li className={(spaax === '/user-segmentation') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/user-segmentation') ? 'side-menu__item active' : 'side-menu__item'} to="/user-segmentation">
                                        {/* <span className="side-menu__icon enlarge fa fa-cogs"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Segmentation.png'></img>
                                        <span className="side-menu__label">User Segmentation</span></NavLink>

                                </li>
                                <li className={(spaax === '/case-study') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/case-study') ? 'side-menu__item active' : 'side-menu__item'} to="/case-study">
                                        {/* <span className="side-menu__icon enlarge fa fa-cogs"></span> */}
                                        <img className="side-menu__icon" src='/img/icons/Casestudy.png' width={40}></img>
                                        <span className="side-menu__label">Case Study</span></NavLink>

                                </li>
                                {/* <li className={(spaax === '/settings') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/settings') ? 'side-menu__item active' : 'side-menu__item'} to="/settings">

                                        <img className="side-menu__icon" src='/img/Settings.png'></img>
                                        <span className="side-menu__label">Settings</span></NavLink>

                                </li>
                                <li className={(spaax === '/admin') ? 'slide is-expanded' : 'slide is-expanded'}>
                                    <NavLink className={(spaax === '/admin') ? 'side-menu__item active' : 'side-menu__item'} to="/admin">

                                        <img className="side-menu__icon" src='/img/Admin.png'></img>
                                        <span className="side-menu__label">Admin</span></NavLink>

                                </li> */}


                            </ul>
                        </div>


                    </aside></div>
            </div>
        </div>
    )
}

export default Sidebar