
const axios = require("axios");

const API_BASE = "https://stage.api.mfrdashboard.mindforceresearch.in/v1" // stage
const GSTKEY = ""
const checkToken = (err) => {
  if(err.response.status === 401){
    // window.location.href = "/login";
  }else{
    return { data: { error: "Server down" } };
  }
}

export const postAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  if(!_token){
    _token = data._token
  }
  const headers = {
    // "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  let responseMessage;
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    responseMessage = postApiRes.data;
    if(responseMessage.status ===0){
      if(responseMessage.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return postApiRes.data;
  } catch (err) {
    const msg = {status: 0}
    //console.log(msg)
    return msg
    // return err;
  }
};

export const get = async function (url, data, queryParameter) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "GET",
      url: url,
      data: JSON.stringify(data),
      params : queryParameter,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    
    // await axios.get(url+'receiveData', JSON.stringify(data), {
    //   headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    //   params: { userId: 2 },
    // })
    if(getApiRes.data.status ===0){
      if(getApiRes.data.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return getApiRes.data;
  } catch (err) {
    // checkToken(err)
  }
};

export const uploadImageAPI = async function (url, fileData) {
  
  let _token = localStorage.getItem("token");
  var formData = new FormData();
  console.log('formdata', fileData)
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  // formData.append("files", fileData[0]);
  console.log('form data in api ervice', formData);
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer  " + _token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    // checkToken(postApiRes.data)
    return postApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};

export const checkGSTNumber = async function (GSTNumber) {
  var config = {
    method: "get",
    url: "http://sheet.gstincheck.co.in/check/" + GSTKEY + "/" + GSTNumber,
    headers: {},
  };
  try {
    const postApiRes = await axios(config);
    return postApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};

export const checkPincode = async function(pinCode, country){
  var data = {
    method: "get",
    url: API_BASE+"/user/get_pincode_details?pincode="+pinCode+"&countryCode="+country,
    headers:{}
  };
  try{
    const apiResponse = await axios(data);
    return apiResponse.data;
  }catch(err){
    return { data: { error: "Server down" } };
  }
}
