import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function Whats_trending_in_cyber_security() {


    useEffect(() => {

        document.title = "Whats Trending in Cyber Security - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-8.jpg" className='img-fluid' alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>What's trending in Cyber Security ?</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>A leading network security firm was interested to know which various cyber security technologies are currently being used and adopted by the organizations. To analyze what strategies and trends are running in the market.</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Mix of Online and CATI</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Director level and above</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>400 interviews </p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Italy, Spain, Germany, France, Nordics, Benelux, Europe, and UAE                                        </p></div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The research helped the client determine which strategies are being adopted, enhanced, or planned for by organizations. The project sampling provided visibility into the use of cyber security technologies in various departments within large organizations in order to better evaluate drivers of growing demand.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Whats_trending_in_cyber_security