import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


function CaseStudy() {




    useEffect(() => {

        document.title = "Case Study - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="/cases-detail/driving-adoption-of-aI-aligned-with-business-goals"><img src="img/casestudy/cases-1.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="/cases-detail/driving-adoption-of-aI-aligned-with-business-goals" class="cases-overly">
                                                    <span class="cases-text">Driving adoption of AI aligned with business goals</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="/cases-detail/the-evolution-of-mobile-transport-while-transitioning-from-4G-to-5G"><img src="img/casestudy/cases-2.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="/cases-detail/the-evolution-of-mobile-transport-while-transitioning-from-4G-to-5G" class="cases-overly">
                                                    <span class="cases-text">The evolution of mobile transport while transitioning from 4G to 5G</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/the-evolving-impact-of-the-digital-world-across-different-functions-and-industries"><img src="img/casestudy/cases-3.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/the-evolving-impact-of-the-digital-world-across-different-functions-and-industries" class="cases-overly">
                                                    <span class="cases-text">The evolving impact of the digital world across different functions and industries</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/a-closer-look-at-sd-wan-technologies"><img src="img/casestudy/cases-4.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/a-closer-look-at-sd-wan-technologies" class="cases-overly">
                                                    <span class="cases-text">A closer look at SD WAN Technologies</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/simplify-recruiting-with-the-applicant-tracking-system"><img src="img/casestudy/cases-5.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/simplify-recruiting-with-the-applicant-tracking-system" class="cases-overly">
                                                    <span class="cases-text">Simplify recruiting with the Applicant Tracking System</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/the-role-of-risk-management-technologies"><img src="img/casestudy/cases-6.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/the-role-of-risk-management-technologies" class="cases-overly">
                                                    <span class="cases-text">The role of risk management technologies</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/stay-a-step-ahead-of-fraudulent-threats"><img src="img/casestudy/cases-7.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/stay-a-step-ahead-of-fraudulent-threats" class="cases-overly">
                                                    <span class="cases-text">Stay a step ahead of fraudulent threats</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/whats-trending-in-cyber-security"><img src="img/casestudy/cases-8.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/whats-trending-in-cyber-security" class="cases-overly">
                                                    <span class="cases-text">What’s trending in Cyber Security ?</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/confused-about-blockchain"><img src="img/casestudy/cases-9.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/confused-about-blockchain" class="cases-overly">
                                                    <span class="cases-text">Confused about blockchain?</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/how-businesses-can-leverage-big-data"><img src="img/casestudy/cases-10.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/how-businesses-can-leverage-big-data" class="cases-overly">
                                                    <span class="cases-text">How businesses can leverage Big Data?</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/deploying-ar-in-manufacturing"><img src="img/casestudy/cases-11.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/deploying-ar-in-manufacturing" class="cases-overly">
                                                    <span class="cases-text">Deploying AR in manufacturing</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/travel-destinations-that-are-in-demand"><img src="img/casestudy/cases-12.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/travel-destinations-that-are-in-demand" class="cases-overly">
                                                    <span class="cases-text">Travel Destinations that are in demand</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/get-a-sip-of-bartenders-study"><img src="img/casestudy/cases-13.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/get-a-sip-of-bartenders-study" class="cases-overly">
                                                    <span class="cases-text">Get a sip of Bartenders Study</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/living-the-luxe-life"><img src="img/casestudy/cases-14.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/living-the-luxe-life" class="cases-overly">
                                                    <span class="cases-text">Living the Luxe life</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/gear-up-for-smart-education"><img src="img/casestudy/cases-15.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/gear-up-for-smart-education" class="cases-overly">
                                                    <span class="cases-text">Gear up for Smart Education</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/what-drives-consumers-to-buy-mobile-phones"><img src="img/casestudy/cases-16.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/what-drives-consumers-to-buy-mobile-phones" class="cases-overly">
                                                    <span class="cases-text">What drives consumers to buy Mobile Phones</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/where-is-your-next-destination"><img src="img/casestudy/cases-17.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/where-is-your-next-destination" class="cases-overly">
                                                    <span class="cases-text">Where is your Next Destination?</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/importance-of-healthcare-professionals-of-tomorrow"><img src="img/casestudy/cases-18.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/importance-of-healthcare-professionals-of-tomorrow" class="cases-overly">
                                                    <span class="cases-text">Importance of healthcare professionals of tomorrow</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/deepening-the-understanding-of-trends-in-e-commerce"><img src="img/casestudy/cases-19.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/deepening-the-understanding-of-trends-in-e-commerce" class="cases-overly">
                                                    <span class="cases-text">Deepening the understanding of trends in e-commerce</span>
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-xl-3 col-lg-3 col-md-4 work-grid-box wow fadeInUp">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <a href="cases-detail/covid-19-and-changing-workforce-strategies"><img src="img/casestudy/cases-20.jpg" alt="cases" /></a>
                                                </div>
                                                <a href="cases-detail/covid-19-and-changing-workforce-strategies" class="cases-overly">
                                                    <span class="cases-text">COVID-19 and changing workforce strategies</span>
                                                </a>
                                            </div>
                                        </div>

                                            {/* end boxes all */}
                                       
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default CaseStudy