
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { checkExpiry } from "../../Common/ExpiryTimeCheck";
import { getMasterData } from "../../Api/MasterData";
import { saveGeoDetail, validateToken } from "../../Api/Auth";

function Geo_detail() {

    const [token, setToken] = useState(localStorage.getItem('token'))
    const [states, setstates] = useState()
    const [countries, setCountries] = useState()
    const [state, setState] = useState();
    const [country, setCountry] = useState();
    const [stateserr, setstateserr] = useState()
    const [Countryerr, setCountryerr] = useState()
    const initialValues = { area: "", postal: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        checkToken()
    },[])
    const checkToken = async () =>{
        let res = await validateToken();
        if(res.status === 0){
            localStorage.clear();
            navigate("/");
        }
    }
    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('country')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getCountries();
            } else {
                data = JSON.parse(localStorage.getItem('country'))
                if (data.status === 1) {
                    setCountries(data.payload);
                }
            }
        }

    }, [])
    const getCountries = async () => {
        let data = await getMasterData({ moduleName: 'country', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setCountries(data.payload);
        }
    }

    // useEffect(() => {
    //     if (token) {
    //         let checkExpiryTime = checkExpiry('state')
    //         let data
    //         if (checkExpiryTime === 'Expired') {
    //             data = getStates();
    //         } else {
    //             data = JSON.parse(localStorage.getItem('state'))
    //             if (data.status === 1) {
    //                 setstates(data.payload);
    //             }
    //         }
    //     }

    // }, [])
    const getStates = async () => {
        if(country === "1"){
            let data = await getMasterData({ moduleName: 'state', pagePerRec: 250, page: 1, parentId: 1 })
            if (data.status === 1) {
                setstates(data.payload);
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const onUsersign = async () => {
        if (!country) {
            return setCountryerr("Required field")
        }
       
        setFormErrors(validate(formValues));
        if(formErrors.postal || formErrors.area){
            return
        }else{
            let data = {
                countryId: country,
                stateId: state,
                postalCode: formValues.postal,
                area: formValues.area
            }
            let res = await saveGeoDetail(data)
            if (res.status === 1) {
                setstates(res.payload);
                setIsSubmit(true);
                localStorage.setItem('userDetail', JSON.stringify(res.payload))
                navigate("/sign-up/professional-info")
            } else {
                setError(res.message)
            }
        }
    }
 
    const validate = (values) => {
        const errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regex = /^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/
        var PincodeRegEx = /^\d{1,10}$/;
        var AddressRegEx = /^[a-zA-Z0-9\s,'-]*$/;

        if (!values.postal) {
            errors.postal = "Required Field!";
        } else if (!PincodeRegEx.test(values.postal)) {
            errors.postal = "This is not a valid Postal!";
        }
        if (!values.area) {
            errors.area = "Required Field!";
        } else if (!AddressRegEx.test(values.area)) {
            errors.area = "This is not a valid Area!";
        }
        return errors;
    };



    useEffect(() => {
        document.title = "Sign In | Mindforce - Admin & Dashboard"
    }, []);

    return (
        <div className="page-main">
            {/* <!-- App-Content -->			 */}
            <div className="main-content">
                <div className="side-app">
                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh align-items-center background-white">
                            <div className="col-lg-5 col-md-12 col-sm-12 h-100" id="login-responsive">
                                <div className="card-body pr-9 pl-9 pt-7">
                                    <img src='/img/logo-big.png' className='mb-4'></img>
                                    < >
                                        <div className="card border-0">
                                            <div className="card-body pb-4 pt-0 sign">
                                                <div className="divider">
                                                    <div className="divider-text text-muted">
                                                        <small>  Geo info </small>
                                                    </div>
                                                </div>
                                                {error ? (<>
                                                    <div className="col-lg-12">
                                                        <div className='errorMessageform'>{error}</div>
                                                        <br /><br />
                                                    </div>
                                                </>) : (<></>)}
                                                <div className="input-box mb-2">
                                                    <label for="country" className="fs-12 font-weight-bold text-md-right  mb-0  mb-0">Country <span className="required_f">*</span><span className='errorMessage'> {Countryerr}</span></label>
                                                    <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => { setCountry(e.target.value); getStates(); }}>
                                                        <option value={""} disabled selected>Select Country</option>
                                                        {countries?.map((cou) => {
                                                            return (<>
                                                                <option value={cou.id}>{cou.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                {
                                                    
                                                   country == "1" &&

                                                    <div className="input-box mb-2">
                                                        <label for="country" className="fs-12 font-weight-bold text-md-right  mb-0  mb-0">State </label>
                                                        <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" onChange={(e) => { setState(e.target.value) }} data-callback="userEnableTable">
                                                            <option value={""} disabled selected>Select State</option>
                                                            {states?.map((sta) => {
                                                                return (<>
                                                                    <option value={sta.id}>{sta.name}</option>
                                                                </>)
                                                            })}
                                                        </select>
                                                    </div>
                                                }
                                                <div className="input-box mb-2">
                                                    <label for="postal" className="fs-12 font-weight-bold text-md-right  mb-0  mb-0">Postal Code <span className="required_f">*</span><span className='errorMessageform'> {formErrors.postal}</span></label>
                                                    <input id="postal" type="text" className="form-control " name="postal" placeholder="Postal Code" value={formValues.postal} onChange={handleChange} />
                                                </div>
                                                <div class="input-box ">
                                                    <label className="fs-12 font-weight-bold text-md-right  mb-0  mb-0">Area <span className="required_f">*</span><span className='errorMessageform'> {formErrors.area}</span></label>
                                                    <input type="text" className="form-control " name="area" placeholder="Enter Area" value={formValues.area} onChange={handleChange} />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <button onClick={() => onUsersign()} className="btn btn-primary mr-2">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 text-center background-special h-100 align-middle pl-0 pr-0" id="login-background">
                                <div className="login-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Geo_detail