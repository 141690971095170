import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { CheckPicker } from 'rsuite';
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import { getMasterData } from '../../Api/MasterData';
import { getPanelBookB2b } from '../../Api/PanelBook';
import Preloader from '../Common/Preloader';
import { changeNumberFormat, numberToWord } from '../../Common/NumberConversion';
import { Chart } from "react-google-charts";
import { get } from '../../ApiService';

  const options = {
    pieHole: 0.5,
    is3D: false,
    // legend: 'none',
    chartArea: {
        left: "3%",
        top: "3%",
        height: "94%",
        width: "94%",
        vAxis: {format:'0.00000000'}
    },
    tooltip: { isHtml: true, trigger: "visible" }
  };

function Business_to_Business() {

    // fields
    const [analytics, setAnalytics] = useState()
    const [chartData, setChartData] = useState()
    const token = localStorage.getItem('token');
    const [regions, setRegions] = useState();
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState();
    const [empSizes, setEmpSizes] = useState();
    const [revenueSizes, setRevenueSizes] = useState();
    const [designations, setDesignations] = useState();
    const [departments, setDepartments] = useState();
    const [selectedRegionDetail, setSelectedRegionDetail] = useState([]);
    const [selectedRegion, setselectedRegion] = useState([])
    const [country, setCountry] = useState([]);
    const [Industry, setIndustry] = useState([])
    const [employee_size, setemployee_size] = useState([])
    const [Revenue_size, setRevenue_size] = useState([])
    const [Title, setTitle] = useState([])
    const [Department, setDepartment] = useState([])
    const [noData, setNoData] = useState()
    const [totalSampleSize, setTotalSampleSize] = useState();
    // error msg
    useEffect(() => {
        document.title = "Business to Business - Mindforce"
    },);

    useEffect(() => {
        getAnnalytics()
    },[])
    const getAnnalytics = async() =>{
        let res = await get('/dashboard/get-analytics/')
        if(res.status === 1){
            setAnalytics(res.payload)
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('region')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getRegions();
            } else {
                data = JSON.parse(localStorage.getItem('region'))
                if (data.status === 1) {
                    setRegions(data.payload);
                }
            }
            // let selReg = [];
            // data?.payload?.forEach(ele => {
            //     console.log('parseInt(ele.id)', parseInt(ele.id))
            //     selReg.push(parseInt(ele.id))
            // });
            // console.log('selReg', selReg)
            // setselectedRegion(selReg)
            // console.log('selected', selectedRegion)
        }
    }, [])
    const getRegions = async () => {
        let data = await getMasterData({ moduleName: 'region', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRegions(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            getCountries();
        }

    }, [selectedRegion])
    const getCountries = async () => {
        let countryArray = [];
        for (let i = 0; i < selectedRegion.length; i++) {
            let data = await getMasterData({ moduleName: 'country', pagePerRec: 250, page: 1, parentId:selectedRegion[i] })
            if (data.status === 1) {
                for (let j = 0; j < data.payload.length; j++) {
                    countryArray.push({ label: data.payload[j].name, value: data.payload[j].id, parentRegion:selectedRegionDetail[i].name, parentId:selectedRegion[i] })
                    // setCountries(data.payload);
                }
            }
        }
        setCountries(countryArray);
        updateCountryOnChangeRegion(countryArray)
    }

    const updateCountryOnChangeRegion = (allRegionCountry) => {
        let allCountryId = []
        for(let i = 0; i < allRegionCountry.length; i++){
            allCountryId.push(allRegionCountry[i].value)
        }

        let finalCountry = []
        country.forEach(e => {
            if(allCountryId.indexOf(e) > -1){
                finalCountry.push(e)
            }
        });
        setCountry(finalCountry)
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('industry')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getIndustries();
            } else {
                data = JSON.parse(localStorage.getItem('industry'))
                if (data.status === 1) {
                    setIndustries(data.payload);
                }
            }
            // let selIndustry = [];
            // data?.payload?.forEach(ele => {
            //     selIndustry.push(parseInt(ele.id))
            // });
            // setIndustry(selIndustry)
        }
        
    }, [])
    const getIndustries = async () => {
        let data = await getMasterData({ moduleName: 'industry', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIndustries(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('employee-size')
            let data;
            if (checkExpiryTime === 'Expired') {
                data = getEmpSize();
            } else {
                data = JSON.parse(localStorage.getItem('employee-size'))
                if (data.status === 1) {
                    setEmpSizes(data.payload);
                }
            }
        }
        
    }, [])

    const getEmpSize = async () => {
        let data = await getMasterData({ moduleName: 'employee-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setEmpSizes(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('revenue-size')
            let data;
            if (checkExpiryTime === 'Expired') {
                data = getRevenueSize();
            } else {
                data = JSON.parse(localStorage.getItem('revenue-size'))
                if (data.status === 1) {
                    setRevenueSizes(data.payload);
                }
            }
        }

    }, [])
    const getRevenueSize = async () => {
        let data = await getMasterData({ moduleName: 'revenue-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRevenueSizes(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('titles')
            let data;
            if (checkExpiryTime === 'Expired') {
                data = getDesignation();
            } else {
                data = JSON.parse(localStorage.getItem('titles'))
                if (data.status === 1) {
                    setDesignations(data.payload);
                }
            }
        }

    }, [])
    const getDesignation = async () => {
        let data = await getMasterData({ moduleName: 'titles', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setDesignations(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('departments')
            let data;
            if (checkExpiryTime === 'Expired') {
                data = getDepartment();
            } else {
                data = JSON.parse(localStorage.getItem('departments'))
                if (data.status === 1) {
                    setDepartments(data.payload);
                }

            }
        }

    }, [])
    const getDepartment = async () => {
        let data = await getMasterData({ moduleName: 'departments', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setDepartments(data.payload);
        }

    }

    const handleFilter = async (type, value, detail) => {
        let btn = document.getElementById(type+value);
        let select = true;
        let data = []
        switch (type) {
            case "region":
                data = selectedRegion;
                let regionDetail = selectedRegionDetail;
                if(selectedRegion.indexOf(value) === -1){
                    data.push(value)
                    regionDetail.push(detail)
                    select = true
                }else{
                    data.splice(selectedRegion.indexOf(value),1)  
                    regionDetail.filter((item) => item.id !== value)
                    select = false
                }
                setselectedRegion(data);
                setSelectedRegionDetail(regionDetail);
                getCountries()
                break;

            case "country":
                console.log('val', value)
                setCountry(value)
                getB2bData({country:value})
                break;
            // industries
            case "indus":
                data = Industry;
                if(Industry.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Industry.indexOf(value),1)
                    select = false
                }
                setIndustry(data);
                break;

            // employee_size
            case "emp":
                data = employee_size
                if(employee_size.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(employee_size.indexOf(value),1)
                    select = false
                }
                setemployee_size(data);
                break;

            // Designation or Title
            case "design":
                data = Title
                if(Title.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Title.indexOf(value),1)
                    select = false
                }
                setTitle(data);
                break;

            // Revenue Size
            case "rev":
                data = Revenue_size;
                if(Revenue_size.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Revenue_size.indexOf(value),1)
                    select = false
                }
                setRevenue_size(data);
                break;

            // Department
            case "dep":
                data = Department;
                if(Department.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Department.indexOf(value),1)
                    select = false
                }
                setDepartment(data);
                break;

            default:
                break;
        }
        if(select){
            btn.classList.remove("btn-toggle-deselected")
            btn.classList.add("btn-toggle-selected")
        }else{
            btn.classList.remove("btn-toggle-selected")
            btn.classList.add("btn-toggle-deselected")
        }
        getB2bData();
    }


    useEffect(() => {
        getB2bData();
    },[country])
    const getB2bData = async(countryData) => {
        setNoData()
        setChartData()
        setTotalSampleSize()
        let countrySel = countryData ? countryData : country
        let filter = [
            { param: "region", value: selectedRegion },
            { param: "industry", value: Industry },
            { param: "employeeSize", value: employee_size },
            { param: "revenueSize", value: Revenue_size },
            { param: "title", "value": Title },
            { param: "department", value: Department },
            { param: "country", value: countrySel}
        ]
        let res = await getPanelBookB2b({ filter: filter, pagePerRec: 250, page: 1, searchText:'' })
        if(res.status === 1){
            // let data = [];
            let data2 = [['name', 'value' ,{ role: "tooltip", type: "string", p: {html: true}}]]
            res.payload.forEach(element => {
                let dataArray = [element.industryName, element.totalDataValue, '<div>'+element.industryName+'<br />'+element.totalDataValue+' ('+element.percentageDataValue+'%)</div>']
                // data.push({ label: element.industryName, value: element.totalDataValue },)
                data2.push(dataArray);
            });
            setChartData(data2)
            let sampleSize = 0
            res.payload.forEach(sample => {
                sampleSize = sampleSize+sample.totalDataValue
            });
            setTotalSampleSize(changeNumberFormat(sampleSize.toString()))
        }else{
            setNoData(res.message)
        }
    }

    const handleSelectedCountry = (e) => {

    }

    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app">
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a > <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Panelbook.png'></img>Panel Book</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a >B2B</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}

                                <div className='mb-3'>
                                    <img src='/img/bannerclient/B2B-PANEL-BOOK.jpg' alt='' className='banner' />
                                    <div class="mb-5 mt-5">
                                        {analytics ? (<>
                                            <p style={{fontSize:"18px", fontWeight:"500", color:"#031b4e"}}>Mindforce Research started its online sample operations in 2011 and is currently
                                                engaging over {parseFloat(analytics?.b2cTotalDataSize/1000000).toFixed(2)} million consumers and more than {parseFloat(analytics?.b2bTotalDataSize/1000000).toFixed(2)} million B2B respondents in
                                                over {analytics?.countriesCovered ? analytics?.countriesCovered : ''} countries in the Americas, Europe, Middle East and Asia Pacific. &nbsp; 
                                                <a href="/Panel-Summary.pdf" target="_blank" style={{textDecoration:"uderline", fontWeight:"bold", color:"#ffa707"}}>
                                                    Download Panel Summary
                                                </a>
                                            </p>
                                        </>) : (<>
                                        <div style={{paddingTop:"25px", paddingBottom:"25px"}}>
                                            <center><img src="/img/loading3.gif" style={{height:"20px"}} /></center>
                                        </div>
                                        </>)}
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 col-sm-12">
                                        <div className="card border-0">
                                            <div className="card-header">
                                                <h3 className="card-title chart-title">{totalSampleSize ? (<>Total Panel Size: <span className='chart-title-num'>{totalSampleSize}</span></>) : (<></>)}</h3>
                                            </div>
                                            <div className="card-body pt-2">
                                                {chartData ? (<>
                                                    <center>
                                                        <Chart
                                                            chartType="PieChart"
                                                            width="100%"
                                                            height="350px"
                                                            data={chartData}
                                                            options={options}
                                                        />
                                                    </center>
      
                                                </>) : (<>
                                                    {noData ? (<>
                                                    <center>
                                                        <div style={{minHeight:"400px", paddingTop:"200px"}}>{noData}</div>
                                                    </center>
                                                </>) : (<>
                                                    <Preloader />
                                                </>)}
                                                </>)}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 col-sm-12 tog">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Region</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {regions?.map((region) => (<button id={"region"+region.id} className={selectedRegion?.indexOf(region.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('region', region.id, region)}>{region.name}</button>))}
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <CheckPicker data={countries} placeholder="Choose Country" groupBy="parentRegion" size="sm" block 
                                                            onChange={(e) => handleFilter('country', e)} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Employee Size</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {empSizes?.map((emp) => {
                                                        return (<>
                                                            <button id={"emp"+emp.id} className={employee_size?.indexOf(emp.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('emp', emp.id)}>{emp.name}</button>
                                                        </>)
                                                    })}
                                                    </div>


                                                    </div>
                                                </div>
                                            </div>
                
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Revenue Size</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {revenueSizes?.map((rev) => {
                                                        return (<>
                                                            <button id={"rev"+rev.id} className={Revenue_size?.indexOf(rev.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('rev', rev.id)}>{rev.name}</button>
                                                        </>)
                                                    })}
                                                    </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Department</h3>
                                                    </div>
                                                    <div className="card-body pt-2">

                                                    <div className='togglebtn'>
                                                    {departments?.map((dep) => {
                                                        return (<>
                                                            <button id={"dep"+dep.id} className={Department?.indexOf(dep.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('dep', dep.id)}>{dep.name}</button>
                                                        </>)
                                                    })}
                                                    </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Designation/ Title</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {designations?.map((design) => {
                                                        return (<>
                                                            <button id={"design"+design.id} className={Title?.indexOf(design.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('design', design.id)}>{design.name}</button>
                                                        </>)
                                                    })}
                                                    </div>


                                                    </div>
                                                </div>
                                            </div>
                                       
                                     
                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Business_to_Business