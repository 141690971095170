import { json } from "react-router-dom";
import { postAPI, get } from "../ApiService";
import { expiryTime } from "../Common/ExpiryTimeCheck";

export const getMasterData = async({moduleName, subModuleName, pagePerRec=250, page=1, searchText, parentId, time}) =>{
    let payloadData = {
        typeSlug: moduleName,
        page: page,
        recPerPage: pagePerRec,
        q: searchText,
        parent: parentId
    }
    let res = await postAPI('/master-data/list/', payloadData)
    res.expiryTime = expiryTime(time)
    let key = moduleName+(subModuleName ? subModuleName : '')
    localStorage.setItem(key, JSON.stringify(res));
    return res;
}