import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { postAPI } from '../ApiService';

function Login_page() {

    const navigate = useNavigate();
    const [email, setemail] = useState();
    const [password, setPassword] = useState();
    const [passwordType, setPasswordType] = useState(true);
    const [keepLoggedIn, setKeepLoggedIn] = useState(0);
    const [emailError, setemailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate("/dashboard");
        }
    })
    const handleData = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        switch (name) {
            case 'email':
                setemail(value)
                break;
            case 'password':
                setPassword(value);
                break;

            default:
                break;
        }
    }

    const onUserLogin = async() => {
        setError();
        if (!email) {
            return setemailError(" *Required")
        }
        if (!password) {
            return setPasswordError(" *Required")
        }
        let data = {
            email : email,
            password : password,
            keepLoggedIn: keepLoggedIn ? 1 : 0
        }
        let res = await postAPI('/auth/login/', data)
        if(res.status === 1){
            localStorage.setItem('token', res.payload.token);
            localStorage.setItem('userDetail', JSON.stringify(res.payload))
            navigate("/dashboard");
        }else{
            setError(res.message)
        }
    }

    useEffect(() => {
        document.title = "Sign In | Mindforce - Admin & Dashboard"
    }, []);

    return (
        <div className="page-main">

            {/* <!-- App-Content -->			 */}
            <div className="main-content">
                <div className="side-app">
                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh  login-page-sec">
                            <div className='col-12 mb-5'>
                                 <div className='header-top text-start'>
                                    <div className='img-box'>
                                        <img src='/img/logo-big.png' className='my-3' width={200}></img>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='col-12'>
                                <div className='small-main-box'>
                                    <ul>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Country.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Country</div>
                                                <div>52</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Corporate.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Corporate</div>
                                                <div>5Lakh +</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/White-Collar.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>White Collar</div>
                                                <div>1.9M +</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Job-Title.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Job Title</div>
                                                <div>300 +</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Consumer.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Consumer</div>
                                                <div>8.2M +</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Decission-Maker.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Decission Maker</div>
                                                <div>12%</div>
                                            </h3>
                                        </li>
                                        <li>
                                            <div className='img-box'>
                                                <img src='/img/login-page/Industry.png' width={40}/>
                                            </div>
                                            <h3> 
                                                <div>Industry</div>
                                                <div>90 +</div>
                                            </h3>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 p-4 ">
                                <h1 className='text-blue-theme h1-heading'>Survey Efficiency Management </h1>
                                <div className='row'>
                                    <div className='col-lg-5 d-lg-flex align-items-center text-center'>
                                       <div className='gradient-main-box'>
                                            <div className='gradient-border-box'>
                                                <div className='img-box'>
                                                    <img src='/img/login-page/Online-Survey.jpg' />
                                                </div>
                                            </div>
                                       </div>
                                       <div className='right-arrow'>
                                            <span className='d-lg-block d-none'>&#129054;</span>
                                            <span className='d-lg-none d-block'>&#129055;</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 d-flex align-items-center'>
                                       <div className='row no-gutters'>
                                            <div className='col-6'>
                                                <div className='gradient-main-box p-3 m-2'>
                                                    <img src='/img/login-page/Multistage-Sampling-Estimation.png' width={80}/>
                                                    <h3>Multistage Sampling Estimation </h3>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='gradient-main-box p-3 m-2'>
                                                    <img src='/img/login-page/Survey-Scripting-Calculator.png' width={80}/>
                                                    <h3>Survey Scripting Calculator </h3>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='gradient-main-box p-3 m-2'>
                                                    <img src='/img/login-page/Data-Modeling.png' width={80}/>
                                                    <h3>Data Modeling </h3>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='gradient-main-box p-3 m-2'>
                                                    <img src='/img/login-page/Segmentation.png' width={80}/>
                                                    <h3>Segmentation </h3>
                                                </div>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                            {/* left sec end */}
                            <div className="col-lg-4 col-md-12 col-sm-12 mb-5" >
                                <div className="card-body">
                                    
                                    <div className="card border-0">
                                            <div className="card-body p-5 pb-0">
                                                <div className="divider">
                                                    <div className="divider-text text-muted">
                                                        <small> login </small>
                                                    </div>
                                                </div>


                                                <div className="input-box mb-4">
                                                    <label for="email" className="fs-12 font-weight-bold text-md-right">Email Address <span className='errorMessage'> {emailError}</span></label>
                                                    <input id="email" type="email" className="form-control " name="email" autocomplete="off" placeholder="Email Address" value={email} onChange={(e) => handleData(e)} required />

                                                </div>

                                                <div className="input-box mb-4">
                                                    <label for="password" className="fs-12 font-weight-bold text-md-right">Password <span className='errorMessage'> {passwordError}</span></label>
                                                    <input id="password" type={passwordType ? 'password':'text'} className="form-control " name="password" value={password} onChange={(e) => handleData(e)} autocomplete="off" placeholder="Password" required />
                                                    <i className="fa fa-eye" style={{ cursor: "pointer", position:"relative",float: "right", top:"-28px",right:"10px"}} onClick={() => setPasswordType(!passwordType)}></i>
                                                    <div className="ml-auto forgotpass">
                                                        <a className="text-info fs-12" href="/forgot-password">Forgot Your Password?</a>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="">
                                                        <label className="custom-switch">
                                                            <input type="checkbox" className="custom-switch-input" name="remember" id="remember" onChange={() => setKeepLoggedIn(!keepLoggedIn)} />
                                                            <span className="custom-switch-indicator"></span>
                                                            <span className="custom-switch-description">Keep me logged in</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <span className='errorMessage mt-0'> {error}</span>
                                                    <label for="error" className="form-label"> </label>
                                                    <button onClick={() => onUserLogin()} className="btn btn-primary mr-2 bg-gradient-theme border-0">Login</button>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="">
                                                        <label className="custom-switch">
                                                            <span className="custom-switch-description">Don't have an account</span> <a className="text-info fs-12" href="/sign-up">Sign Up</a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            {/* form end */}
                            <div className='col-12 mb-5'>
                                <div className='large-btns-sec'>
                                    <div className='btn-left'>
                                        <a href='https://stage.gotosample.com/' className='side-left-right-btn' target='_blank'>Consumer panel (B2B/B2C)</a>
                                    </div>
                                    <div className='btn-right'>
                                        <a href='https://stage.mfrslm.mindforceresearch.in/' className='side-left-right-btn' target='_blank'>Survey Management Tool</a>
                                    </div>
                                </div>
                            </div>
                            {/* btns sec end */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login_page