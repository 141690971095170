import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Segmentcard from '../../Segmentcard';

function User_segmentation() {

   


    useEffect(() => {

        document.title = "User Segmentation - Mindforce"



    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                       
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a > <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Segmentation.png'></img>User Segmentation</a></li>
                                            
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='segment_sec'>
                                    {Segmentcard.card_data.map((item, index) => {
                                   return <div className='' >
                                <div class="SearchResults-module_result_3-uXL worksheet"><div class=""><a href=""><div class="ContentCard-module_card_1xFCo ContentCard-module_grid_2cGfg"><a class="ContentCard-module_link_bi8gq" href="" data-id="63741" data-type="worksheet"><div class="ContentCard-module_front__1Cvl"><div class="ContentCard-module_image_1D6-7 ContentCard-module_narrow_1Ktvs"><img alt="1-Minute Multiplication" src={item.img}/></div><div class="ContentCard-module_titleArea_sGOV3">
                                    <div class="font-primary-bold Title-module_title_21JAg Title-module_grid_3aQxO"><span>{item.title}</span></div>
                                <div class="ContentType-module_contentTypeHolder_7Ku6w ContentType-module_grid_1TWyv"> <div class="ContentType-module_contentTypeName_2IUAM">
                                    </div></div></div></div></a><div class="ContentCard-module_hoverSection_2GeOZ"><div class="font-primary-bold Title-module_title_21JAg"><span>{item.title}</span></div>
                                <div class="ContentCard-module_description_1ejK0">
                               <ul className='ul_style'>
                              {item.list.map((item_val, index) => {
                                return <li>{item_val.li_val}</li>
                              })}
                                </ul>
                               </div>
                                   </div>
                                      </div></a></div></div>
                                </div>
                                })}
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default User_segmentation