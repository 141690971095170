import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function Gear_up_for_smart_education() {


    useEffect(() => {

        document.title = "Gear up for Smart Education - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-15.jpg" className='img-fluid' alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>Gear up for Smart Education</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The continuous technological advancements has brought new opportunities and possibilities in todays education system. To examine this in detail, we targeted students within different universities to understand the growing importance of education and get the overview of education trends in different markets and how smart education has impacted  students.</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Online Method</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Students (year of graduation 2018-2019)</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>400 interviews</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>NA, EU, APAC and MEA</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Our research helped the client understand how colleges and universities can leverage technology to differentiate themselves to students and the extent to which students feel Smart Education Applications may be beneficial to them on their path to future careers.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Gear_up_for_smart_education