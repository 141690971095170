import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function Confused_About_blockchain() {


    useEffect(() => {

        document.title = "Confused about blockchain? - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-9.jpg" className='img-fluid' alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>Confused about blockchain?</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>We helped our client; a market research firm understand the importance of Blockchain for the future of the companies and the major factors for pursuing Blockchain. The level of difficulty involved while working with external partners and stakeholders.</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>CATI</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Senior IT Decision Maker and above</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>750 interviews</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>North America, CA, Switzerland, Europe, Singapore, Denmark, Norway, Sweden, Finland, ANZ, CN, JP</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The client used the survey results to identify the functional areas and business processes that are impacted by Blockchain and the areas that can be automated by Blockchain.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Confused_About_blockchain