import React, { useState } from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect } from 'react';
import Data from '../../Form_Data';
import { CheckPicker } from 'rsuite';
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import { getMasterData } from '../../Api/MasterData';
import { getB2bSampleSize, saveB2bData } from '../../Api/SurveySampling';
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { masterData } from '../../Api/Master';
import { changeNumberFormat } from '../../Common/NumberConversion';

function Survey_sampling_b2b() {

    const token = localStorage.getItem('token');
    const [regions, setRegions] = useState();
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState();
    const [empSizes, setEmpSizes] = useState();
    const [revenueSizes, setRevenueSizes] = useState();
    const [designations, setDesignations] = useState();
    const [departments, setDepartments] = useState();
    const [selectedRegionDetail, setSelectedRegionDetail] = useState([]);
    const [selectedRegion, setselectedRegion] = useState([])
    const [country, setCountry] = useState([]);
    const [Industry, setIndustry] = useState([])
    const [employee_size, setemployee_size] = useState([])
    const [Revenue_size, setRevenue_size] = useState([])
    const [Title, setTitle] = useState([])
    const [Department, setDepartment] = useState([])
    const [noData, setNoData] = useState()
    const [totalSampleSize, setTotalSampleSize] = useState();

    useEffect(() => {
        document.title = "Survey Sampling B2B - Mindforce"
    },);

    const [levelOfSurvey, setLevelOfSurvey] = useState()
    const [surveyDuration, setSurveyDuration] = useState()
    const [surveyStartFrom, setSurveyStartFrom] = useState()
    const [surveyEndFrom, setSurveyEndFrom] = useState()
    const [ir, setIR] = useState()
    const [surveySamplingMethod, setSurveySamplingMethod] = useState([])

    const [levelOfSurveys, setLevelOfSurveys] = useState()
    const [surveyDurations, setSurveyDurations] = useState()
    const [irs, setIRs] = useState()
    const [surveySamplingMethods, setSurveySamplingMethods] = useState()

    // error msg
    const [levelOfSurveYError, setLevelOfSurveYError] = useState()
    const [surveyDurationError, setSurveyDurationError] = useState()
    const [surveyStartError, setSurveyStartError] = useState()
    const [irError, setIrError] = useState()
    const [surveyEndError, setSurveyEndError] = useState()
    const [surveyMethodError, setSurveyMethodError] = useState()
    const [sampleSize, setSampleSize] = useState();

    useEffect(() => {
        masterDate()
    },[])

    const masterDate = async () =>{
        // let serveyLevelData = await getMasterData({ moduleName: 'survey-level', pagePerRec: 250, page: 1 })
        // setLevelOfSurveys(serveyLevelData?.payload);
        await getRegions()
        await getCountries()
        await getIndustries()
        await getEmpSize()
        await getRevenueSize()
        await getDesignation()
        await getDepartment()
        await getSurveyLevel()
        await getSurveyDuration()
        await getIrs()
        await getSurveySamplingMethod()
    }
    const getSurveyLevel = async () => {
        let data = await masterData({ moduleName: 'survey-level', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setLevelOfSurveys(data.payload);
        }
    }
    const getSurveyDuration = async () => {
        let data = await masterData({ moduleName: 'survey-duration', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setSurveyDurations(data.payload);
        }
    }
    const getIrs = async () => {
        let data = await masterData({ moduleName: 'estimated-ir', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIRs(data.payload);
        }
    }
    const getSurveySamplingMethod = async () => {
        let data = await masterData({ moduleName: 'sampling-method', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setSurveySamplingMethods(data.payload);
        }
    }

    const getRegions = async () => {
        let data = await masterData({ moduleName: 'region', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRegions(data.payload);
        }
    }

    useEffect(() => {
        getCountries();
    }, [selectedRegion])

    const getCountries = async () => {
        let countryArray = [];
        for (let i = 0; i < selectedRegion.length; i++) {
            let data = await masterData({ moduleName: 'country', pagePerRec: 250, page: 1, parentId: selectedRegion[i] })
            if (data.status === 1) {
                for (let j = 0; j < data.payload.length; j++) {
                    countryArray.push({ label: data.payload[j].name, value: data.payload[j].id, parentRegion: selectedRegionDetail[i].name, parentId: selectedRegion[i] })
                    // setCountries(data.payload);
                }
            }
        }
        setCountries(countryArray);
        updateCountryOnChangeRegion(countryArray)
    }
    const updateCountryOnChangeRegion = async (allRegionCountry) => {
        let allCountryId = []
        for(let i = 0; i < allRegionCountry.length; i++){
            allCountryId.push(allRegionCountry[i].value)
        }

        let finalCountry = []
        country.forEach(e => {
            if(allCountryId.indexOf(e) > -1){
                finalCountry.push(e)
            }
        });
        console.log('finalCountry',finalCountry)
        setCountry(finalCountry)
    }
    const getIndustries = async () => {
        let data = await masterData({ moduleName: 'industry', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIndustries(data.payload);
        }
    }

    const getEmpSize = async () => {
        let data = await masterData({ moduleName: 'employee-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setEmpSizes(data.payload);
        }
    }

    const getRevenueSize = async () => {
        let data = await masterData({ moduleName: 'revenue-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRevenueSizes(data.payload);
        }
    }

    const getDesignation = async () => {
        let data = await masterData({ moduleName: 'titles', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setDesignations(data.payload);
        }
    }

    const getDepartment = async () => {
        let data = await masterData({ moduleName: 'departments', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setDepartments(data.payload);
        }
    }

    const handleFilter = async (type, value, detail) => {
        let btn = document.getElementById(type+value);
        let select = true;
        let data = []
        switch (type) {
            case "region":
                data = selectedRegion;
                let regionDetail = selectedRegionDetail;
                if(selectedRegion.indexOf(value) === -1){
                    data.push(value)
                    regionDetail.push(detail)
                    select = true
                }else{
                    data.splice(selectedRegion.indexOf(value),1)  
                    regionDetail.filter((item) => item.id !== value)
                    select = false
                }
                setselectedRegion(data);
                setSelectedRegionDetail(regionDetail);
                getCountries()
                break;

            case "country":
                console.log('val', value)
                setCountry(value)
                getB2bData({country:value})
                break;
            // industries
            case "indus":
                data = Industry;
                if (Industry.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Industry.indexOf(value), 1)
                    select = false
                }
                setIndustry(data);
                break;

            // employee_size
            case "emp":
                data = employee_size
                if (employee_size.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(employee_size.indexOf(value), 1)
                    select = false
                }
                setemployee_size(data);
                break;

            // Designation or Title
            case "design":
                data = Title
                if (Title.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Title.indexOf(value), 1)
                    select = false
                }
                setTitle(data);
                break;

            // Revenue Size
            case "rev":
                data = Revenue_size;
                if (Revenue_size.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Revenue_size.indexOf(value), 1)
                    select = false
                }
                setRevenue_size(data);
                break;

            // Department
            case "dep":
                data = Department;
                if (Department.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Department.indexOf(value), 1)
                    select = false
                }
                setDepartment(data);
                break;

            default:
                break;
                   // Industry
        
        }
        if (select) {
            btn.classList.remove("btn-toggle-deselected")
            btn.classList.add("btn-toggle-selected")
        } else {
            btn.classList.remove("btn-toggle-selected")
            btn.classList.add("btn-toggle-deselected")
        }
        getB2bData();
    }

    useEffect(() => {
        getB2bData();
    }, [country])
    const getB2bData = async (countryData) => {
        setNoData()
        setSampleSize()
        setTotalSampleSize()
        let countrySel = countryData ? countryData : country
        let filter = [
            { param: "region", value: selectedRegion },
            { param: "industry", value: Industry },
            { param: "employeeSize", value: employee_size },
            { param: "revenueSize", value: Revenue_size },
            { param: "title", "value": Title },
            { param: "department", value: Department },
            { param: "country", value: countrySel }
        ]
        let res = await getB2bSampleSize({payloadData:filter})
        if(res.status === 1){
            // setTotalSampleSize(res.payload.totalSampleSize)
            setSampleSize(res?.payload?.totalSampleSize)
            setTotalSampleSize(changeNumberFormat(res?.payload?.totalSampleSize?.toString()))
        }
    }

    const handleSampleMethod = (e) => {
        let value = parseInt(e.target.value)
        let data = surveySamplingMethod;
        if (surveySamplingMethod.indexOf(value) === -1) {
            data.push(value)
        } else {
            data.splice(surveySamplingMethod.indexOf(value), 1)
        }
        setSurveySamplingMethod(data);
    }

    const submitData = async () => {
        let error = false;
        setLevelOfSurveYError()
        setSurveyDurationError()
        setSurveyStartError()
        setSurveyEndError()
        setIrError()
        setSurveyMethodError()
        let startDate = Math.floor(new Date(surveyStartFrom?.toString()).getTime()/1000)
        let endDate = Math.floor(new Date(surveyEndFrom?.toString()).getTime()/1000)
        if(!levelOfSurvey){
            setLevelOfSurveYError(" *Required")
            error = true
        }if(!surveyDuration){
            setSurveyDurationError(" * Required")
            error = true
        }
        if(!surveyStartFrom){
            setSurveyStartError(" * Requied")
            error = true
        }
        if(!surveyEndFrom){
            setSurveyEndError(" * Required")
            error = true
        }
        if(endDate - startDate  < 1){
            setSurveyEndError(" * Invalid")
            error = true
        }
        if(!ir){
            setIrError(" * Required")
            error = true
        }
        if(surveySamplingMethod?.length < 1){
            setSurveyMethodError(" * Required")
            error = true
        }
        if(error){
            return error
        }
        let payloadData = {
            regionID: selectedRegion,
            countryID: country,
            employeeSizeID: employee_size,
            revenueSizeID: Revenue_size,
            departmentID: Department,
            titleID: Title,
            industryID: Industry,
            surveyLevelID: parseInt(levelOfSurvey),
            surveyDurationID: parseInt(surveyDuration),
            surveyStartDate: startDate,
            surveyEndDate: endDate,
            estimatedIRID: parseInt(ir),
            samplingMethodID: surveySamplingMethod,
            totalSampleSize:sampleSize
        }
        let res = await saveB2bData({payloadData:payloadData})
        if(res.status === 1){
            Swal.fire({
                // title: 'Calculating...',
                html:'<p style="color:#031b4e">Your survey data has been uploaded successfully. Our consultant will touch base with the estimation soon.</p>',
                showCancelButton:false,
                showCloseButton: false,
                showConfirmButton:true,
                showDenyButton:false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                // timer: 1500,
            }).then((result) => {
                if(result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    }
    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app">
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/SurveySampling.png'></img>Survey Sampling</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a>B2B</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='mb-3'>
                                    <img src='/img/bannerclient/B2B-SURVEY-SAMPLING.jpg' alt='' className='banner' />

                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 tog">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Region</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {regions?.map((region) => (<button id={"region" + region.id} className={selectedRegion?.indexOf(region.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('region', region.id, region)}>{region.name}</button>))}
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <CheckPicker data={countries} placeholder="Choose Country" groupBy="parentRegion" size="sm" block
                                                            onChange={(e) => handleFilter('country', e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Employee Size</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {empSizes?.map((emp) => {
                                                                return (<>
                                                                    <button id={"emp" + emp.id} className={employee_size?.indexOf(emp.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('emp', emp.id)}>{emp.name}</button>
                                                                </>)
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Revenue Size</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {revenueSizes?.map((rev) => {
                                                                return (<>
                                                                    <button id={"rev" + rev.id} className={Revenue_size?.indexOf(rev.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('rev', rev.id)}>{rev.name}</button>
                                                                </>)
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Department</h3>
                                                    </div>
                                                    <div className="card-body pt-2">

                                                        <div className='togglebtn'>
                                                            {departments?.map((dep) => {
                                                                return (<>
                                                                    <button id={"dep" + dep.id} className={Department?.indexOf(dep.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('dep', dep.id)}>{dep.name}</button>
                                                                </>)
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Designation/ Title</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {designations?.map((design) => {
                                                                return (<>
                                                                    <button id={"design" + design.id} className={Title?.indexOf(design.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('design', design.id)}>{design.name}</button>
                                                                </>)
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Industry</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {industries?.map((indus) => {
                                                                return (<>
                                                                    <button id={"indus" + indus.id} className={Industry?.indexOf(indus.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('indus', indus.id)}>{indus.name}</button>
                                                                </>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="card border-0">
                                            <div className="card-header">
                                                <h3 className="card-title chart-title">Total  Size: <span className='chart-title-num'>{totalSampleSize ? totalSampleSize : ''}</span></h3>
                                            </div>
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Level of Survey <span className='errorMessage'> {levelOfSurveYError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setLevelOfSurvey(e.target.value)} data-placeholder="Select " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select </option>
                                                                {levelOfSurveys?.map((item) => {
                                                                    return (<option value={item.id}>{item.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Survey Duration in Minutes <span className='errorMessage'> {surveyDurationError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setSurveyDuration(e.target.value)} data-placeholder="Select SEC " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select Survey Duration</option>
                                                                
                                                                {surveyDurations?.map((item) => {
                                                                    return (<option value={item.id}>{item.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group form_fill_formcontrol">
                                                            <h6 className="task-heading">Survey Starting Date <span className='errorMessage'> {surveyStartError}</span></h6>
                                                            <input className='form-control' min={format(new Date(), 'yyyy-MM-d').toString()} type="date" min={format(new Date(), 'yyyy-MM-d').toString()} onChange={(e) => setSurveyStartFrom(e.target.value)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group form_fill_formcontrol">
                                                            <h6 className="task-heading">Survey Ending Date <span className='errorMessage'> {surveyEndError}</span></h6>
                                                            <input className='form-control' min={format(new Date(), 'yyyy-MM-d').toString()} type="date" min={format(new Date(), 'yyyy-MM-d').toString()} onChange={(e) => setSurveyEndFrom(e.target.value)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Estimated IR <span className='errorMessage'> {irError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setIR(e.target.value)} data-placeholder="Select SEC " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select </option>
                                                                
                                                                {irs?.map((item) => {
                                                                    return (<option value={item.id}>{item.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-2">
                                                        <div className="form-group ">
                                                            <p style={{color:"red", fontSize:"12px", fontWeight:"900"}}> {surveyMethodError}</p>
                                                            <div className='d-flex survey_sampling_checkbox'>
                                                                <h6 className="task-heading">Survey Sampling Method:<span className='errorMessage'> {surveyMethodError}</span></h6>
                                                                {surveySamplingMethods?.map((item) => {
                                                                    return (<>
                                                                    <input type="checkbox"
                                                                        className='checkbox ml-6 '
                                                                        name="check_tra"
                                                                        value={item.id}
                                                                        onChange={(e) => handleSampleMethod(e)}
                                                                    />
                                                                    <h6 className="task-heading mr-4 ml-1">{item.name}</h6>
                                                                    </>)
                                                                })}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <button disabled={sampleSize ? false : true} className="btn btn-primary main-action-button" onClick={submitData}>Express your Interest</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Survey_sampling_b2b