import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Data from '../../Form_Data'; 
import { CheckPicker } from 'rsuite';
import {  useEffect,useState } from 'react';
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import { getMasterData } from '../../Api/MasterData';
import DonutChart from "react-donut-chart";
import Preloader from '../Common/Preloader';
import { getPanelBookB2c } from '../../Api/PanelBook';
import { changeNumberFormat, numberToWord } from '../../Common/NumberConversion';
import { Chart } from "react-google-charts";
import { get } from '../../ApiService';

const options = {
    pieHole: 0.5,
    is3D: false,
    // legend: 'none',
    chartArea: {
        left: "3%",
        top: "3%",
        height: "94%",
        width: "94%",
        vAxis: {format:'0.00000000'}
    },
    tooltip: { isHtml: true, trigger: "visible" }
    // tooltip: { isHtml: true, trigger: "visible" }
  };
  
function Business_to_Consumer() {

    const [analytics, setAnalytics] = useState()
    const [chartData, setChartData] = useState()
    const token = localStorage.getItem('token')
    // fields
    const [regions, setRegions] = useState();
    const [countries, setCountries] = useState([]);
    const [genders, setGenders] = useState();
    const [ages, setAges] = useState();
    const [occupations, setOccupations] = useState()
    const [incomes, setIncomes] = useState();
    const [secs, setSecs] = useState();
    const [selectedRegion, setselectedRegion] = useState([])
    const [selectedRegionDetail, setSelectedRegionDetail] = useState([]);
    const [country, setCountry] = useState([])
    const [Gender, setGender] = useState([])
    const [Age, setAge] = useState([])
    const [Sec, setSec] = useState([])
    const [occupation, setOccupation] = useState([])
    const [income, setIncome] = useState([]);
    const [noData, setNoData] = useState()
    const [totalSampleSize, setTotalSampleSize] = useState();
    let reactDonutChartStrokeColor = "#FFFFFF";
    const reactDonutChartOnMouseEnter = (item) => {
        let color = chartData?.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
    };
    useEffect(() => {
        getAnnalytics()
    },[])
    const getAnnalytics = async() =>{
        let res = await get('/dashboard/get-analytics/')
        if(res.status === 1){
            setAnalytics(res.payload)
        }
    }

      const handleFilter = async (type, value, detail) => {
        let btn = document.getElementById(type+value);
        let data = []
        let select = true;
        switch (type) {
            case "region":
                data = selectedRegion;
                let regionDetail = selectedRegionDetail;
                if(selectedRegion.indexOf(value) === -1){
                    data.push(value)
                    regionDetail.push(detail)
                    select = true
                    
                }else{
                    data.splice(selectedRegion.indexOf(value),1)
                    regionDetail.filter((item) => item.id !== value)
                    select = false
                    
                }
                setselectedRegion(data);
                setSelectedRegionDetail(regionDetail);
                console.log(regionDetail)
                getCountries()
                break;

            case "country":
                setCountry(value)
                getB2cData(value)
                break;

            // gender
            case "gender":
                data = Gender;
                if(Gender.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Gender.indexOf(value),1)
                    select = false
                }
                setGender(data);
                break;

            // Age
            case "age":
                data = Age
                if(Age.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(Age.indexOf(value),1)
                    select = false
                }
                setAge(data);
                break;

            // Occupation
            case "occupation":
                data = occupation
                if(occupation.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(occupation.indexOf(value),1)
                    select = false
                }
                setOccupation(data);
                break;
            
            // Income
            case "income":
                data = income
                if(income.indexOf(value) === -1){
                    data.push(value)
                    select = true
                }else{
                    data.splice(income.indexOf(value),1)
                    select = false
                }
                setIncome(data);
                break;

            default:
                break;
        }
        if(type !== 'country'){
            if(select){
                btn.classList.remove("btn-toggle-deselected")
                btn.classList.add("btn-toggle-selected")
            }else{
                btn.classList.remove("btn-toggle-selected")
                btn.classList.add("btn-toggle-deselected")
            }
        }
        
        getB2cData()
    }

    useEffect(() => {
        document.title = "Business to Consumer - Mindforce"
    },);

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('region-b2c')
            if(checkExpiryTime === 'Expired'){
                getRegions();
            }else{
                let data = JSON.parse(localStorage.getItem('region-b2c'))
                if(data.status === 1){
                    setRegions(data.payload);
                }
            }
        }
        
    },[])
    const getRegions = async() =>{
        let data = await getMasterData({moduleName:'region-b2c', pagePerRec:250, page:1})
        if(data.status === 1){
            setRegions(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            getCountries();
        }

    }, [selectedRegion])
    const getCountries = async () => {
        let countryArray = [];
        for (let i = 0; i < selectedRegion.length; i++) {
            let data = await getMasterData({ moduleName: 'country-b2c', pagePerRec: 250, page: 1, parentId:selectedRegion[i] })
            if (data.status === 1) {
                for (let j = 0; j < data.payload.length; j++) {
                    countryArray.push({ label: data.payload[j].name, value: data.payload[j].id, parentRegion:selectedRegionDetail[i].name, parentId:selectedRegion[i] })
                    setCountries(data.payload);
                }
            }
        }
        await setCountries(countryArray);
        await updateCountryOnChangeRegion(countryArray)
    }

    const updateCountryOnChangeRegion = async (allRegionCountry) => {
        let allCountryId = []
        for(let i = 0; i < allRegionCountry.length; i++){
            allCountryId.push(allRegionCountry[i].value)
        }

        let finalCountry = []
        country.forEach(e => {
            if(allCountryId.indexOf(e) > -1){
                finalCountry.push(e)
            }
        });
        console.log('finalCountry',finalCountry)
        setCountry(finalCountry)
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('gender')
            if(checkExpiryTime === 'Expired'){
                getGender();
            }else{
                let data = JSON.parse(localStorage.getItem('gender'))
                if(data.status === 1){
                    setGenders(data.payload);
                }
            }
        }
        
    },[])
    const getGender = async() =>{
        let data = await getMasterData({moduleName:'gender', pagePerRec:250, page:1})
        if(data.status === 1){
            setGenders(data.payload);
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('age-group-b2c')
            if(checkExpiryTime === 'Expired'){
                getAges();
            }else{
                let data = JSON.parse(localStorage.getItem('age-group-b2c'))
                if(data.status === 1){
                    setAges(data.payload);
                }
            }
        }
        
    },[])
    const getAges = async() =>{
        let data = await getMasterData({moduleName:'age-group-b2c', pagePerRec:250, page:1})
        if(data.status === 1){
            setAges(data.payload);
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('occupation')
            if(checkExpiryTime === 'Expired'){
                getOccupations();
            }else{
                let data = JSON.parse(localStorage.getItem('occupation'))
                if(data.status === 1){
                    setOccupations(data.payload);
                }
            }
        }
        
    },[])
    const getOccupations = async() =>{
        let data = await getMasterData({moduleName:'occupation', pagePerRec:250, page:1})
        if(data.status === 1){
            setOccupations(data.payload);
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('income-group')
            if(checkExpiryTime === 'Expired'){
                getIncome();
            }else{
                let data = JSON.parse(localStorage.getItem('income-group'))
                if(data.status === 1){
                    setIncomes(data.payload);
                }
            }
        }
        
    },[])
    const getIncome = async() =>{
        let data = await getMasterData({moduleName:'income-group', pagePerRec:250, page:1})
        if(data.status === 1){
            setIncomes(data.payload);
        }
    }

    useEffect(() => {
        getB2cData();
    },[country])
    const getB2cData = async(countryData) => {
        
        setNoData()
        setChartData()
        let countrySel = countryData ? countryData : country
        let filter = [
            { param: "region", value: selectedRegion },
            { param: "country", value: countrySel},
            { param: "occupation", value: occupation },
            { param: "income", value: income },
            { param: "gender", value: Gender },
            { param: "age", value: Age },
        ]
        let res = await getPanelBookB2c({ filter: filter, pagePerRec: 250, page: 1, searchText:'' })
        if(res.status === 1){
            let data = [];
            let data2 = [['name', 'value']]
            res.payload.forEach(element => {
                // data.push({ label: element.occupation, value: element.totalDataValue },)
                let dataArray = [element.occupation, element.totalDataValue]
                data2.push(dataArray);
            });
            setChartData(data2)
            let sampleSize = 0
            res.payload.forEach(sample => {
                sampleSize = sampleSize+sample.totalDataValue
            });
            setTotalSampleSize(changeNumberFormat(sampleSize.toString()))
        }else{
            setNoData(res.message)
        }
    }

    const handleSelectedCountry = (e) => {
        console.log(e)
    }

    
    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                       
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Panelbook.png'></img>Panel Book</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a >B2C</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='mb-3'>
                                    <img src='/img/bannerclient/B2C-PANEL-BOOK.jpg' alt='' className='banner'/>
                                    <div class="mb-5 mt-5">
                                        {analytics ? (<>
                                            <p style={{fontSize:"18px", fontWeight:"500", color:"#031b4e"}}>Mindforce Research started its online sample operations in 2011 and is currently
                                                engaging over {parseFloat(analytics?.b2cTotalDataSize/1000000).toFixed(2)} consumers and more than {parseFloat(analytics?.b2bTotalDataSize/1000000).toFixed(2)} B2B respondents in
                                                over {analytics?.countriesCovered} countries in the Americas, Europe, Middle East and Asia Pacific.   &nbsp; 
                                                <a href="/Panel-Summary.pdf" target="_blank" style={{textDecoration:"uderline", fontWeight:"bold", color:"#ffa707"}}>
                                                Download Panel Summary
                                                </a>
                                            </p>
                                        </>) : (<>
                                            <div style={{paddingTop:"25px", paddingBottom:"25px"}}>
                                                <center><img src="/img/loading3.gif" style={{height:"20px"}} /></center>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="card border-0">
                                            <div className="card-header">
                                                <h3 className="card-title chart-title">{totalSampleSize ? (<>Total Panel Size: <span className='chart-title-num'>{totalSampleSize}</span></>) : (<></>)}</h3>
                                            </div>
                                            <div className="card-body pt-2">
                                                {chartData ? (<>
                                                    <center>
                                                        <Chart
                                                            chartType="PieChart"
                                                            width="100%"
                                                            height="350px"
                                                            data={chartData}
                                                            options={options}
                                                        />
                                                    </center>
                                                </>) : (<>
                                                {noData ? (<>
                                                    <center>
                                                        <div style={{minHeight:"400px", paddingTop:"200px"}}>{noData}</div>
                                                    </center>
                                                </>) : (<>
                                                    <Preloader />
                                                </>)}
                                                
                                                </>)}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 tog">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Region</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {regions?.map((region) => (<button id={"region"+region.id} className={selectedRegion?.indexOf(region.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('region', region.id, region)}>{region.name}</button>))}
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <CheckPicker data={countries} placeholder="Choose Country" groupBy="parentRegion" size="sm" block 
                                                            onChange={(e) => handleFilter('country', e)} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Gender</h3>
                                                    </div>
                                                    <div className="card-body pt-2">

                                                    <div className='togglebtn'>
                                                    {genders?.map((gen) => (<button id={"gender"+gen.id} className={Gender?.indexOf(gen.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('gender', gen.id)}>{gen.name}</button>))}
                                                    </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Age Group</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {ages?.map((age) => (<button id={"age"+age.id} className={Age?.indexOf(age.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('age', age.id)}>{age.name}</button>))}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Occupation</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {occupations?.map((occ) => (<button id={"occupation"+occ.id} className={occupation?.indexOf(occ.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('occupation', occ.id)}>{occ.name}</button>))}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Monthly Household Income </h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {incomes?.map((inc) => (<button id={"income"+inc.id} className={income?.indexOf(inc.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('income', inc.id)}>{inc.name}</button>))}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Business_to_Consumer