import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useCountUp } from 'react-countup';
import CountUp from 'react-countup';
import { useEffect ,useState} from 'react';
import { get } from '../../ApiService';


function Dashboard() {
    const [analytics, setAnalytics] = useState();
    useEffect(() => {
        document.title = "Dashboard - Mindforce"
    },);

    useEffect(() => {
        getAnalytics()
    },[])

    const getAnalytics = async () =>{
        let res = await get('/dashboard/get-analytics/')
        if(res.status === 1){
            setAnalytics(res.payload)
        }
    }
    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page dashboard_survey">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app">
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item fs-17"><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Dashboard.png'></img>Dashboard</a></li>
                                            {/* <li className="breadcrumb-item active" aria-current="page"><a href="/Business-to-Business">Business-to-Business</a></li> */}
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='row mb-4 mt-4' >
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="work-part-inner">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-6 wow fadeInUp animated" style={{ visibility: " visible" }}>
                                                    <div class="work-counter text-center">
                                                        <span class="counter-nummber ms-animated" ><CountUp delay={0} end={analytics?.b2bTotalDataSize} separator=","  /></span>
                                                        <label class="counter-name">Global B2B Panel</label>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-6 wow fadeInUp animated" style={{ visibility: " visible" }}>
                                                    <div class="work-counter text-center ">
                                                        <span class="counter-nummber counter-color ms-animated" ><CountUp delay={0} end={analytics?.b2cTotalDataSize} separator=","  /></span>
                                                        <label class="counter-name">Global B2C Panel</label>
                                                    </div>
                                                </div>
                                                {/* <div class="col-xl-3 col-lg-3 col-md-3 col-6 wow fadeInUp animated" style={{ visibility: " visible" }}>
                                                    <div class="work-counter text-center">
                                                        <span class="counter-nummber ms-animated" ><CountUp delay={0} end={2829}  /></span>
                                                        <label class="counter-name">Global Retail Panel</label>
                                                    </div>
                                                </div> */}
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-6 wow fadeInUp animated" style={{ visibility: " visible" }}>
                                                    <div class="work-counter text-center">
                                                        <span class="counter-nummber counter-color ms-animated" ><CountUp delay={0} end={ analytics?.countriesCovered} separator=","   /></span>
                                                        <label class="counter-name">Countries Covered</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className='card_dash'>
                                    <div className='row'>
                                        <div className='col-xl-8 col-lg-8 col-md-8 col-6'>
                                            <div class="section-headding pt-2">
                                                <label class="section-topik">ABOUT US</label>
                                                <h2 class="section-title"><span>We are passionate about</span> <br />primary research perfection</h2>
                                            </div>
                                            <p className='color-white'>With the winning integration of cutting-edge research technology, deep domain knowledge and a skilfully vetted pool of respondents from across the globe, we have been successfully delivering B2B and B2C market insights since 2010. Our core team consists of seasoned marketing research professionals with rich multi-functional experience across industries, methodologies and geographies.</p>

                                            <a href='https://www.mindforceresearch.com/what-we-do' target="_blank" >  <button class="btn-oreng">Read more <i class="fa fa-angle-right" aria-hidden="true"></i></button></a>


                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-4 col-6'>
                                            <img className='about_img' src='/img/website_pic1.png'></img>
                                            <img src="/img/dot.svg" alt="Dot" class="dot-svg wow fadeInLeft animated" />
                                            <img src="/img/research-1.svg" alt="Dot" class="circle-svg wow fadeInLeft animated" />
                                        </div>
                                    </div>


                                </div>

                                <section class="service-part pt-100 ">
                                   
                                        <div class="row">
                                            <div class="col-xl-7 col-lg-7 col-md-7 ">
                                                <div class="service-left wow fadeInLeft animated" >
                                                    <div className='row'>
                                                        <div className='col-xl-12 col-lg-12 col-md-12 flex-end'>
                                                            <div class="headding-button">
                                                                <div class="section-headding color-headding">
                                                                    <label class="section-topik">OUR SERVICES</label>
                                                                    <h2 class="section-title">What we offer</h2>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-xl-12 col-lg-12 col-md-12 '>


                                                            <div className='work_service_img'>
                                                            <img src="/img/dash/research.png"></img>
                                                            <img src="/img/dash/onlineeservice.png"></img>
                                                            <img src="/img/dash/surveyscript.png"></img>
                                                            <img src="/img/dash/dataprocessing.png"></img>
                                                            <img src="/img/dash/dataanalysis.png"></img>
                                                            <img src="/img/dash/harting.png"></img>
                                                            <img src="/img/dash/estimate.png"></img>
                                                            <img src="/img/dash/modelling.png"></img>
                                                                <img src="/img/dash/brand.png"></img>
                                                                
                                                               
                                                               
                                                                
                                                               
                                                                
                                                                
                                                                <img src="/img/dash/supportservice.png"></img>
                                                               
                                                                
                                                            </div>
                                                            {/* <div class="service-tab">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="https://thoughtcom.in/demos/mindforce/what-we-offer" target="_blank">
                                            <img src="/img/search1.svg" alt="search"/>
                                            <div className='tab_text pt-2'><span>PRIMARY <br/>RESEARCH <br/>SERVICES</span></div>
                                            
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link"  href="https://thoughtcom.in/demos/mindforce/online-sampling" target="_blank">
                                            <img src="/img/mobile-icon.svg" alt="search"/>
                                            <div className='tab_text pt-2'><span>ONLINE <br/>SAMPLING</span></div>
                                            
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="https://thoughtcom.in/demos/mindforce/support-services" target="_blank">
                                            <img className='mt0' src="/img/research-icon.svg" alt="search"/>
                                            <div className='tab_text pt-2'><span>SUPPORT <br/>SERVICES</span></div>
                                           
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-5 col-lg-5 col-md-5 service-img-dekstop">
                                                <div class="service-right wow fadeInRight animated" >
                                                    <img src="/img/Dashboard_Pic2.jpg" class="image-redius" alt="ABOUT US" />
                                                    <img src="/img/dot.svg" alt="Dot" class="dot-svg wow fadeInLeft animated" />
                                                    <img src="/img/dubbolround-border.svg" alt="Dot" class="service-right_imgcircle wow fadeInLeft animated" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                </section>
                                <section class="service-part1 pt-100 ">
                                   <div class="row">
                                        <div className='col-xl-12 col-lg-12 col-md-12 flex-end'>
                                            <div class="headding-button">
                                                <div class="section-headding color-headding">
                                                    <label class="section-topik service-part1_mar">ESOMAR 37</label>
                                                    {/* <h2 class="section-title">What we offer</h2> */}
                                                </div>

                                            </div>
                                        </div>
                                       <div class="col-xl-12 col-lg-12 col-md-12 ">
                                            <div className=''>
                                                <iframe src="img/files/MindforceResearch-ESOMAR37.pdf" width="100%" height="500px"/>
                                            </div>
                                       </div>
                                   </div>
                           </section>
                        </div>
                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Dashboard