import React, { useState } from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect } from 'react';
import Usages_data from '../../Usages_data';
import { getMasterData } from '../../Api/MasterData';
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import { s3bucket } from '../../Common/Constant';
import Loader from "react-js-loader";
import { getCategoryProductList } from '../../Api/CategoryProduct';
import { changeNumberFormat } from '../../Common/NumberConversion';
import Preloader from '../Common/Preloader';

function Usage_habits() {
    const token = localStorage.getItem('token')
    const [active, setactive] = useState()
    const [loading, setLoading] = useState(false)
    const [selectedCategory, setselectedCategory] = useState()
    const [selectedCategoryDetail, setselectedCategoryDetail] = useState()
    const [Categories, setCategories] = useState();
    const [categoryProducts, setCategoryProducts] = useState();
    const [genders, setGenders] = useState();
    const [ages, setAges] = useState();
    const [countries, setCountries] = useState();

    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedAge, setSelectedAge] = useState([]);
    const [selectedSec, setSelectedSec] = useState(); 
    const [noData, setNoData] = useState()
    useEffect(() => {
        document.title = "Usage Habits - Mindforce"
    },);
    const click_category = (item) => {
        setCategoryProducts();
        setactive(item.slug)
        setselectedCategory(item.slug)
        setselectedCategoryDetail(item)
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('product-category')
            console.log('checkExpiryTime ',checkExpiryTime)
            if(checkExpiryTime === 'Expired'){
                getCategory();
            }else{
                let data = JSON.parse(localStorage.getItem('product-category'))
                setCategories(data.payload);
                setactive(data.payload[0].slug)
                setselectedCategory(data.payload[0].slug)
            }
        }
        
    },[selectedAge, selectedCountry, selectedGender])
    const getCategory = async() =>{
        let data = await getMasterData({moduleName:'product-category', pagePerRec:250, page:1, time:0})
        setCategories(data.payload);
    }

    useEffect(() => {
        if(token){
            setLoading(true)
            let checkExpiryTime = checkExpiry('products'+selectedCategory)
            if(checkExpiryTime === 'Expired'){
                getCategoryProduct();
            }else{
                let data = JSON.parse(localStorage.getItem('products'+selectedCategory))
                setCategoryProducts(data.payload);
                setLoading(false)
            } 
        }
        
    },[selectedCategory, Categories, selectedAge[0], selectedGender[0], selectedCountry[0]])

    const getCategoryProduct = async() => {
        setLoading(true)
        setNoData();
        setCategoryProducts();
        let parentId;
        if(selectedCategoryDetail){
            parentId = selectedCategoryDetail.id
        }else{
            parentId = Categories?.[0].id
        }
        if(parentId){
            let payloadData = {
                page: 1,
                recPerPage: 100,
                q: "",
                parent: parentId,
                filters: [
                    { param: "countryId", value: selectedCountry },
                    { param: "genderId", value: selectedGender },
                    { param: "ageId", value: selectedAge }
                ]
            }
            let data = await getCategoryProductList({moduleName:'products', subModuleName:selectedCategory, payloadData: payloadData, time:0})
            if(data.status === 1){
                setCategoryProducts(data.payload);
                
            }else{
                setNoData(data.message)
            }
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('country')
            if(checkExpiryTime === 'Expired'){
                getCountries();
            }else{
                let data = JSON.parse(localStorage.getItem('country'))
                if(data.status === 1){
                    setCountries(data.payload);
                }
            }
        }
        },[])
        
    const getCountries = async() =>{
        let data = await getMasterData({moduleName:'country', pagePerRec:10, page:1})
        if(data.status === 1){
            setCountries(data.payload);
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('gender')
            if(checkExpiryTime === 'Expired'){
                getGender();
            }else{
                let data = JSON.parse(localStorage.getItem('gender'))
                if(data.status === 1){
                    setGenders(data.payload);
                }
            }
        }
        
    },[])
    const getGender = async() =>{
        let data = await getMasterData({moduleName:'gender', pagePerRec:10, page:1})
        if(data.status === 1){
            setGenders(data.payload);
        }
    }

    useEffect(()=>{
        if(token){
            let checkExpiryTime = checkExpiry('age-group')
            if(checkExpiryTime === 'Expired'){
                getAges();
            }else{
                let data = JSON.parse(localStorage.getItem('age-group'))
                if(data.status === 1){
                    setAges(data.payload);
                }
            }
        }
        
    },[])
    const getAges = async() =>{
        let data = await getMasterData({moduleName:'age-group', pagePerRec:10, page:1})
        if(data.status === 1){
            setAges(data.payload);
        }
    }

    const changeFilter = (e, filter) => {
        let data = [];
        console.log('e.target.value', e.target.value)
        switch (filter) {
            case 'country':
                if(selectedCountry.length > 0){
                    data = selectedCountry;
                    data.pop()
                    setSelectedCountry(data);
                }
                if(e.target.value !== 'Country'){
                    data.push(parseInt(e.target.value))
                }
                setSelectedCountry(data)
                getCategoryProduct();
                break;
            case 'gender':
                
                if(selectedGender.length > 0){
                    data = selectedGender;
                    data.pop()
                    setSelectedGender(data);
                }
                if(e.target.value !== 'Gender'){
                    data.push(parseInt(e.target.value))
                }
                    
                setSelectedGender(data)
                getCategoryProduct();
                break;

            case 'age':
                if(selectedAge.length > 0){
                    data = selectedAge;
                    data.pop()
                    setSelectedAge(data);
                }
                if(e.target.value !== 'Age'){
                    data.push(parseInt(e.target.value))
                }
                setSelectedAge(data)
                getCategoryProduct();
                break;
            default:
                break;
        }
    }
    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app" style={{pointerEvents:!loading ? '' : 'none'}}>
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item "><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/UsageHabitat.png'></img>Usage Habits</a></li>
                                      </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='mb-4'>
                                <img src='/img/bannerclient/Usage-Habits.jpg' alt='' className='banner'/>
                                </div>
                                <div className='filter'>
                                    <div className='d-flex usages_filter'>
                                        <select className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Country" data-callback="userEnableTable" onChange={(e) => changeFilter(e, 'country')}>
                                            <option value={null} selected>Country</option>
                                            {countries?.map((item, index )=>{
                                                return  <option value={item.id} selected={index===0 ? true : false}>{item.name}</option>
                                            } )}
                                        </select>
                                        <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Gender" data-callback="userEnableTable" onChange={(e) => changeFilter(e, 'gender')}>
                                            <option value={null} selected>Gender</option>
                                            {genders?.map((item )=>{
                                                return  <option value={item.id}>{item.name}</option>
                                            } )}
                                        </select>
                                        <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Age" data-callback="userEnableTable" onChange={(e) => changeFilter(e, 'age')}>
                                            <option value={null} selected>Age </option>
                                            {ages?.map((item )=>{
                                                return  <option value={item.id}>{item.name}</option>
                                            } )}
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="row mt-46 ">
                                    <div className='col-lg-3 col-xl-3 usages'>
                                        <ul className="side-menu ">
                                            {Categories?.map((item, index) => {
                                                return <li className="slide " key={index}>
                                                    <div className={active ? (item.slug === active ? `${active} active side-menu__item_usage ` : 'deactive side-menu__item_usage') : (index === 0 ? ('active side-menu__item_usage') : ('deactive side-menu__item_usage'))} onClick={() => click_category(item)} >
                                                        {/* <span className="side-menu__icon enlarge fa fa-cogs"></span> */}
                                                        <span className="side-menu__label_usage">{item.name}</span><i className="angle_usage fa fa-angle-right"></i></div>
                                                </li>

                                            })}

                                        </ul>
                                    </div>

                                    <div className='col-lg-9 col-xl-9'>
                                        <div className='card usage_card border-0' >
                                            <div className="card-body p-5 ">
                                                <div className='row'>
                                                    {categoryProducts?.length > 0 ? (<>
                                                        {categoryProducts?.map((item) => {
                                                            return <div className="col-sm-6 col-lg-4 col-xl-2 counter    justify-content-center align-items-center text-center text-black position-relative">
                                                                <div className="counter-inner">
                                                                    <img src={s3bucket+item?.description?.icon}></img>
                                                                </div>
                                                                <div className="counter-inner pb-1">
                                                                    <h6 className="mt-20 mb-0">{item.name}<br />{item.dataValue ? changeNumberFormat(item.dataValue.toString()) : 0}</h6>
                                                                    {/* <h6 className="mt-10 mb-0"></h6> */}
                                                                </div>
                                                            </div>
                                                        })}
                                                    </>) : (<>
                                                        {noData ? (<>
                                                            
                                                                <div className='justify-content-center align-items-center text-center' style={{minHeight:"200px", paddingTop:"100px", width:"100%"}}>
                                                                   {noData}
                                                                </div>
                                                            
                                                        </>) : (<>
                                                            <Preloader />
                                                        </>)}
                                                    </>)}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='footer_usages'><Footer /></div> */}
                
            </div>
        </>
    )
}

export default Usage_habits