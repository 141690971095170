import { json } from "react-router-dom";
import { postAPI, get } from "../ApiService";

export const getPanelBookB2b = async({pagePerRec=250, page=1, searchText, filter}) =>{
    let payloadData = {
            page: page,
            recPerPage: pagePerRec,
            q: searchText,
            filters: filter
        }
    
    let res = await postAPI('/panel-book/b2b/', payloadData)
    
    return res;
}

export const getPanelBookB2c = async({pagePerRec=250, page=1, searchText, filter}) =>{
    let payloadData = {
            page: page,
            recPerPage: pagePerRec,
            q: searchText,
            filters: filter
        }
    
    let res = await postAPI('/panel-book/b2c/', payloadData)
    
    return res;
}