import { json } from "react-router-dom";
import { postAPI, get } from "../ApiService";

export const saveMasterData = async({payloadData}) =>{
    let res = await postAPI('/survey-scripting/save/master-data', payloadData)
    return res;
}

export const saveQuestionData = async({payloadData, surveyScriptingID}) =>{
    let res = await postAPI('/survey-scripting/save/question-data/'+surveyScriptingID, payloadData)
    return res;
}

export const getTotalEffortData = async({surveyScriptingID}) =>{
    let res = await get('/survey-scripting/total-efforts/'+surveyScriptingID)
    return res;
}