import React from 'react'

function SidebarCases() {
    return (
        <div class="business-goals-content">
            <ul>

                <li><a href="/cases-detail/driving-adoption-of-aI-aligned-with-business-goals">Driving adoption of AI aligned with business goals</a></li>
                <li><a href="/cases-detail/the-evolution-of-mobile-transport-while-transitioning-from-4G-to-5G">The evolution of mobile transport while transitioning from 4G to 5G</a></li>

                <li><a href="/cases-detail/the-evolving-impact-of-the-digital-world-across-different-functions-and-industries">The evolving impact of the digital world across different functions and industries</a></li>

                <li><a href="/cases-detail/a-closer-look-at-sd-wan-technologies">A closer look at SD WAN Technologies</a></li>

                <li><a href="/cases-detail/simplify-recruiting-with-the-applicant-tracking-system">Simplify recruiting with the Applicant Tracking System</a></li>

                <li><a href="/cases-detail/the-role-of-risk-management-technologies">The role of risk management technologies</a></li>

                <li><a href="/cases-detail/stay-a-step-ahead-of-fraudulent-threats">Stay a step ahead of fraudulent threats</a></li>

                <li><a href="/cases-detail/whats-trending-in-cyber-security">What’s trending in Cyber Security ?</a></li>

                <li><a href="/cases-detail/confused-about-blockchain">Confused about blockchain?</a></li>

                <li><a href="/cases-detail/how-businesses-can-leverage-big-data">How businesses can leverage Big Data?</a></li>

                <li><a href="/cases-detail/deploying-ar-in-manufacturing">Deploying AR in manufacturing</a></li>

                <li><a href="/cases-detail/travel-destinations-that-are-in-demand">Travel Destinations that are in demand</a></li>

                <li><a href="/cases-detail/get-a-sip-of-bartenders-study">Get a sip of Bartenders Study</a></li>

                <li><a href="/cases-detail/living-the-luxe-life">Living the Luxe life</a></li>

                <li><a href="/cases-detail/gear-up-for-smart-education">Gear up for Smart Education</a></li>

                <li><a href="/cases-detail/what-drives-consumers-to-buy-mobile-phones">What drives consumers to buy Mobile Phones</a></li>

                <li><a href="/cases-detail/where-is-your-next-destination">Where is your Next Destination?</a></li>

                <li><a href="/cases-detail/importance-of-healthcare-professionals-of-tomorrow">Importance of healthcare professionals of tomorrow</a></li>

                <li><a href="cases-detail/deepening-the-understanding-of-trends-in-e-commerce">Deepening the understanding of trends in e-commerce</a></li>

                <li><a href="cases-detail/covid-19-and-changing-workforce-strategies">COVID-19 and changing workforce strategies</a></li>
            </ul>
        </div>
    )
}

export default SidebarCases