
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import bytesBrickValidations from "../../bytesBrickValidations";
import OtpVerify from "./Otp_verify";
import { sendOTP } from "../../Api/Auth";

function Geo_info() {
    const navigate = useNavigate();

    const initialValues = { fname: "", email: "", password: "" ,phone:""};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [error, setErrors] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [passwordType, setPasswordType] = useState(true);
    const [ConfirmPasswordType, setConfirmPasswordType] = useState(true);
    const [isOtpSent, setIsOtpSent] = useState(false);
    
    useEffect(() => {
        if(localStorage.getItem('token') && localStorage.getItem('userDetail')){
            navigate("/dashboard");
        }
    })
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };

    const onUsersign = (e) => {
        // e.preventDefault();
        setErrors();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }
    
  useEffect(() => {
    const  savepersonalInfo = async () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            let res = await sendOTP({firstName: formValues.fname, email: formValues.email, source: "web"})
            console.log("res", res)
            if(res.status === 1){
              console.log(res.status)
              setFormValues({ ...formValues, accessToken: res.payload.accessToken, _token:res.payload.token });
            //   localStorage.setItem('token', res.payload.token)
            console.log(formValues);
              setIsOtpSent(true)
            }else{
                setErrors(res.message)
            }
          //   navigate("/sign-up/geo-info")
          }
    }
    savepersonalInfo()
  }, [formErrors]);

    const validate = (values) => {
        console.log('value', values)
        const errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regex = /^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/
        var IndNumberRegEx = /^((\+91)?|91)?[789][0-9]{9}/;
        if (!values.fname) {
          errors.fname = "Required Field!";
        }
        if (!values.email) {
          errors.email = "Required Field!";
        } else if (!regex.test(values.email)) {
          errors.email = "This is not a valid email format!";
        }

        if(values.cpassword !== values.password){
            errors.password = "Password and confirm password not match";
        }

        if (!values.password) {
          errors.password = "Required Field!";
        } else if (values.password.length < 4) {
          errors.password = "Password must be more than 4 characters";
        } else if (values.password.length > 20) {
          errors.password = "Password cannot exceed more than 20 characters";
        }
        
        if (values.phone) {
            if (!IndNumberRegEx.test(values.phone)) {
                errors.phone = "This is not a valid Number!";
              }
          } 
        return errors;
      };
    useEffect(() => {
        document.title = "Sign Up - Mindforce"
    }, []);

    const backTobasicInfo = () =>{
        setIsOtpSent(false)
    }

    return (
        <div className="page-main">

            {/* <!-- App-Content -->			 */}
            <div className="main-content">
                <div className="side-app">

                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh align-items-center background-white">
                            <div className="col-lg-5 col-md-12 col-sm-12 h-100" id="login-responsive">
                                <div className="card-body pr-9 pl-9 pt-7">
                                    <img src='/img/logo-big.png' className='mb-4'></img>
                                    < >
                                        <div className="card border-0">
                                            {isOtpSent ? (<>
                                            <OtpVerify formValues={formValues} backToInfo={backTobasicInfo} />
                                            </>) : (<>
                                                <div className="card-body  pb-4 pt-0 sign">
                                                <div className="divider">
                                                    <div className="divider-text text-muted">

                                                        <small>  personal info </small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                {error ? (<>
                                                        <div className="col-lg-12">
                                                            <div className='errorMessageform'>{error}</div>
                                                            <br /><br />
                                                        </div>
                                                    </>) : (<></>)}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="input-box mb-2 ">
                                                            <label  className="fs-12 font-weight-bold text-md-right  mb-0">First Name <span className="required_f">*</span><span className='errorMessageform'>{formErrors.fname}</span></label>
                                                            <input  type="text" className="form-control " name="fname" autocomplete="off"  autofocus placeholder="First Name"  value={formValues.fname} onChange={handleChange} />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-box mb-2">
                                                            <label for="name" className="fs-12 font-weight-bold text-md-right  mb-0">Last Name </label>
                                                            <input  type="text" className="form-control " name="lname" autocomplete="off"  autofocus placeholder="Last Name"    />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-box mb-2">
                                                    <label for="email" className="fs-12 font-weight-bold text-md-right  mb-0">Corporate Email <span className="required_f">*</span><span className='errorMessageform'> {formErrors.email}</span></label>
                                                    <input id="email" type="email" className="form-control " name="email" autocomplete="off" placeholder="Corporate Email "  value={formValues.email} onChange={handleChange} required />

                                                </div>
                                                <div className="input-box mb-2">
                                                    <label for="password" className="fs-12 font-weight-bold text-md-right  mb-0">Password <span className="required_f">*</span><span className='errorMessageform'> {formErrors.password}</span></label>
                                                    <i className="fa fa-eye" style={{ cursor: "pointer",position: "relative",float: "right",top: "28px",right: "10px"}} onClick={() => setPasswordType(!passwordType)}></i>
                                                    <input id="password" type={passwordType ? 'password' : 'text'} className="form-control " name="password" autocomplete="off" placeholder="Password"  value={formValues.password}  onChange={handleChange} required />
                                                    
                                                </div>
                                                <div className="input-box mb-2">
                                                    <label for="password" className="fs-12 font-weight-bold text-md-right  mb-0"> Confirm Password <span className="required_f">*</span></label>
                                                    <i className="fa fa-eye" style={{ cursor: "pointer",position: "relative",float: "right",top: "28px",right: "10px"}} onClick={() => setConfirmPasswordType(!ConfirmPasswordType)}></i>
                                                    <input id="password" type={ConfirmPasswordType ? 'password' : 'text'} className="form-control " name="cpassword" autocomplete="off" placeholder="Confirm Password" value={formValues.cpassword}  onChange={handleChange} required />
                                                    
                                                </div>
                                                <div className="input-box mb-4">
                                                    <label for="phone" className="fs-12 font-weight-bold text-md-right  mb-0">Mobile Number <span className="required_fop">(Optional)</span><span className='errorMessageform'> {formErrors.phone}</span> </label>
                                                    <input id="phone" type="number" className="form-control onlyNumber" name="phone" autocomplete="off" placeholder="Enter Number"value={formValues.phone}  onChange={handleChange}  required />

                                                </div>
                                                <div className="form-group mb-0">
                                                    <button onClick={() => onUsersign()} className="btn btn-primary mr-2">Next</button>

                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="">
                                                        <label className="custom-switch">
                                                            <span className="custom-switch-description">Already have an account?</span> <a className="text-info fs-12" href="/">Sign in</a>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            </>)}
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 text-center background-special h-100 align-middle pl-0 pr-0" id="login-background">
                                <div className="login-bg"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    )
}

export default Geo_info